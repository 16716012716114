import React from 'react';
// import { useLottie } from "lottie-react";
import PageNotFoundAni from "../../Assets/Lottie/PageNotFoundAni.json"
import Lottie from "lottie-react";
import { ArrowLeft } from "lucide-react";

function PageNotFound() {

  const style = {
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    // transform: "scale(1.2)"
  };


  return (
    <div style={{ position: 'relative', height: '100vh', }}>
      <Lottie
        animationData={PageNotFoundAni}
        style={style}
      // interactivity={interactivity}
      />

      <button className='btn btn-primary' style={{ position: "absolute", bottom: '50px', right: '50px' }}>
        <ArrowLeft size={20} />
        <span className='ms-1'>Go Back</span>
      </button>
    </div>

  )
}

export default PageNotFound