import React, { useEffect, useState } from 'react'
import S3 from "react-aws-s3";
import { Buffer } from 'buffer';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';
import {
    get_tool_sub_cat_api,
    get_tool_cat_api,
    get_tooltype_api,
    get_tooling_parameter_api,
    create_product,
    update_product,
    delete_attribute,
    get_product_api
} from "../../Common/API/index";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
  } from "react-bootstrap";
import { Eye, Trash } from 'lucide-react';
import { useFieldArray, useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ToolSpecs from './ToolSpecs';

const config = {
    bucketName: "make-my-tools-staging",
    dirName: "product",
    region: "ap-south-1",
    accessKeyId: "AKIAXK6RFPYMHWRVULNF",
    secretAccessKey: "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",
    "S3URL": "https://make-my-tools-staging.s3.ap-south-1.amazonaws.com/"
};
const ReactS3Client = new S3(config);

//console.log("ReactS3Client", ReactS3Client)

const schema = yup
    .object({
        tool_type: yup.array()
            .min(1, "Select tool type")
            .required("Select tool type"),
        tool_category: yup.array()
            .min(1, "Select tool category")
            .required("Select tool category"),
        tool_subcategory: yup.array()
            .min(1, "Select tool sub category")
            .required("Select tool sub category"),
        tool_subcategory_code: yup.string().
            max(200, "Maximum 200 characters allowed")
            .required("Enter sub category code"),
        description: yup.string().
            max(500, "Maximum 500 characters allowed")
            .notRequired("Enter description"),
        series: yup.string().
            max(200, "Maximum 200 characters allowed")
            .required("Enter series"),
    })
    .required()

    if (!window.Buffer) {   window.Buffer = Buffer; }

function Create() {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [scale, setScale] = useState(1);
    const [toolTypeList, setToolTypeList] = useState({ data: [], loading: true });
    const [toolCatList, setToolCatList] = useState({ data: [], loading: true });
    const [toolSubCatList, setToolSubCatList] = useState({ data: [], loading: true });
    const [toolParameterList, setToolParameterList] = useState({ data: [], loading: true });
    const [toolCategoryData, setToolCategoryData] = useState({ data: [], loading: true });
    const [toolSubCategoryData, setToolSubCategoryData] = useState({ data: [], loading: true });
    const [product, setProduct] = useState({});
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [totalAttr, setTotalAttr] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [attribute, setAttribute] = useState(
        {
            parameter: {
                "parameters_code": ""
            },
            uom: {},
            uom_value: "",
            tol_positive: "",
            tol_negative: "",
            isbox: false
        }
    );

    const { register, handleSubmit, formState, control, reset, setValue, getValues, watch } =
    useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            tool_type: null,
            tool_category: null,
            tool_subcategory: null
        },

    }); // initialise the hook

    // Initialize field array
    const { fields, append, update, remove } = useFieldArray({
        control,
        name: "attribute"
    });

    let { errors } = formState;
    const selectedtool_type = watch("tool_type");
    const selectedtool_category = watch("tool_category");
    const selectedtool_subcategory = watch("tool_subcategory");
    const selected_attributes = watch("attribute");

    useEffect(() => {
        const handleResize = () => {
          window.location.reload(); // Reload the page on resize
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        fetchToolTypes();
        fetchToolCats();
        fetchToolSubCats();
        fetchToolParameters(); 
    }, []);

    useEffect(() => {
        //console.log("uuid", uuid)
        if(uuid)
        {
            fetchProduct()  
        }
    }, [uuid]);

    const extractValue = (str) => {
        const match = str.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
    };

    const selectParameter = (value, onChange) => {
        if (value && value.length > 0) {
          // Add logic for adding the parameter
          const watchAttributes = watch("attribute");
          const index = watchAttributes.findIndex(x => x.parameter.uuid === value[0].uuid);
      
          if (index === -1) {
            const newAttribute = { ...attribute };
            newAttribute["parameter"] = value[0];
            newAttribute["uom"] = value[0].uomdata;
            newAttribute["uom_value"] = value[0].uomdata.uom;
      
            // Update attribute state
            setAttribute(newAttribute);
      
            // Call onChange to update form state
            onChange(value[0]);
          } else {
            alert("Attribute already exists");
          }
        } else {
          // Logic for removing the parameter when cleared
          const newAttribute = { ...attribute };
          newAttribute["parameter"] = {
            "parameters_code": ""
          };
          newAttribute["uom"] = "";
          newAttribute["uom_value"] = "";
      
          // Update attribute state
          setAttribute(newAttribute);
      
          // Call onChange to update the form state to empty
          onChange(null);  // Pass null to remove the selection
        }
    };

    const modalInputChange = (e) => {
        const newAttribute = {...attribute};
        newAttribute[e.target.name] = e.target.value;
        setAttribute(newAttribute);
    }

    const addAttribute = () => {
        append(
            {
                uuid: "",
                parameter: attribute.parameter,
                uom: attribute.uom,
                uom_value: attribute.uom_value,
                tol_positive: attribute.tol_positive,
                tol_negative: attribute.tol_negative,
                x_position: "",
                y_position: "",
                isbox: attribute.isbox
            }
        )
        setVisible(false)
    };

    const handleAttributeChange = (index, value) => {
        const updatedAttributes = [...attributes];
        updatedAttributes[index].parameter_code = value;
        setAttributes(updatedAttributes);

        const toolAttr = [...totalAttr];
        if (value != "") {
            totalAttr[index].label = extractValue(value[0]?.parameters_code);
        }
        setTotalAttr(toolAttr);
        //console.log("toolAttr", value[0])
    };

    
    const removeAttribute = (index, attribute) => {
        if(attribute.uuid != "")
        {
            try{
                delete_attribute({ attribute_id: attribute.uuid }).then((res) => {
                    toast.success('Attribute deleted successfully!');
                }, (err) => {
                    console.log("err", err);
                    toast.error(err.response.data.message);
                });
                remove(index);
            }catch (error) {
                //toast.error('An error occurred');
                console.error('Attribute delete:', error);
            }
        }else{
            remove(index);
        }
        // Update totalAttr after removing an attribute
        const checkIndex = totalAttr.findIndex(x => x.parameter.uuid === attribute.parameter.uuid);
        if (checkIndex > -1) {
            setTotalAttr(totalAttr.filter((_, i) => i !== checkIndex));
        }

        // Recalculate indices and ensure that all attributes after the removed one are updated correctly
        //reset(getValues());

        
        
        // setAttributes((prevAttributes) => {
        //     const updatedAttributes = [...prevAttributes];
        //     updatedAttributes.splice(index, 1); 
        //     return updatedAttributes;
        // });

        // // Also, update the form state with setValue
        // setValue(`attribute`, (prevAttributes) => {
        //     // Create a new array without the removed attribute
        //     const updatedAttributes = [...prevAttributes];
        //     updatedAttributes.splice(index, 1);
        //     return updatedAttributes;
        // });
    
        // Optional: If you need to synchronize `totalAttr` with `attributes`
        // setTotalAttr((prevTotalAttr) => {
        //     const updatedTotalAttr = [...prevTotalAttr];
        //     updatedTotalAttr.splice(index, 1); // Ensure totalAttr also removes the same index
        //     return updatedTotalAttr;
        // });
    };

    const generateBox = (index) =>{
        //console.log("tt", selected_attributes[index])
        if(selected_attributes[index]?.parameter)
        {
            let paramter = selected_attributes[index]?.parameter;
            let uom = selected_attributes[index]?.uom;
            let uom_value = selected_attributes[index]?.uom_value;
            const checkIndex = totalAttr.findIndex(arr => arr.uuid == paramter.uuid)
            if(checkIndex == -1)
            {
                const attribute = fields[index];
                update(index, 
                    { 
                        ...attribute, 
                        isbox: true,
                        parameter: paramter,
                        uom: uom,
                        uom_value: uom_value,
                    });
                // const newObj = {
                //     uuid: paramter?.uuid,
                //     unit: uom_value,
                //     x: 0,
                //     y: 0,
                //     defaultX: 0,
                //     defaultY: 0
                // };
                // setTotalAttr([...totalAttr, newObj]);
                alert("New box has been added")
            }
        }else{
            toast.error("Please select parameter first");
        }
    }


    const handleStop = (index, e, data) => {
        //console.log("fields", fields)
        //console.log("totalAttr", totalAttr)
        // Get the parent container
        const parent = e.target.closest('.tool-container');
        const parentWidth = parent?.clientWidth;
        const parentHeight = parent?.clientHeight;

        // Calculate the percentage values
        const leftPercent = ((data.x / parentWidth) * 100).toFixed(2);
        const topPercent = ((data.y / parentHeight) * 100).toFixed(2);

        //console.log(`Left: ${leftPercent}%, Top: ${topPercent}%`);

        // Update the state with the final percentage values
        const updatedAttr = [...totalAttr];
        if (index >= 0 && index < updatedAttr.length) {
            updatedAttr[index].x = leftPercent;
            updatedAttr[index].y = topPercent;
            setTotalAttr(updatedAttr);
        } else {
            console.error('Index out of bounds');
        }
    };

    //console.log("selectedtool_type", selectedtool_type);

    

    function fetchProduct() {
        const parent = document.querySelector('.tool-container');
        const parentWidth = parent?.clientWidth; // Fallback to 523 if undefined
        const parentHeight = parent?.clientHeight;
        let obj = {
            "uuid": uuid
        }
        get_product_api(obj).then((res) => {
            let data = res.data.data;
            setProduct(data);
            setValue("tool_type", data.tooltypedata)
            setValue("tool_category", data.toolcategory)
            setValue("tool_subcategory", data.toolsubcategory)
            setValue("product_code", data.product_code)
            setFileUrl(data.product_image)
            //setAttributes(res.data.attributes)
            const attributes = res.data.attributes.map(attr => {
                return{
                    uuid: attr.uuid,
                    parameter: attr.toolparameter,
                    uom: attr.uom,
                    uom_value: attr.uom_value,
                    tol_positive: attr.tol_positive,
                    tol_negative: attr.tol_negative,
                    x_position: attr.x_position,
                    y_position: attr.y_position,
                    isbox: attr.x_position !== ""
                }
                
            });
            //setAttributes(attributes);
            //console.log("attributes", attributes)
            setValue("attribute", attributes);

            

            // let newArray = [];
            // attributes.map((attr, i) => {
            //     //console.log("attr", attr)
            //     const parent = document.querySelector('.tool-container');
            //     const parentWidth = parent?.clientWidth;
            //     const parentHeight = parent?.clientHeight;
            //     if(attr.x_position != "" && attr.y_position != "")
            //     {
            //         const x = 523 * (attr.x_position / 100);
            //         const y = 523 * (attr.y_position / 100);
            //         const newObj = {
            //             uuid: attr.parameter.uuid,
            //             unit: attr.uom_value,
            //             x: attr.x_position,
            //             y: attr.y_position,
            //             defaultX: x,
            //             defaultY: y
            //         };
            //         //console.log("newObj", newObj)
            //         newArray.push(newObj)
            //         //setTotalAttr([...totalAttr, newObj]);
            //         //console.log(totalAttr)
            //     }
            //     setTotalAttr(newArray);
            // })
        }, (err) => {
            console.log("err", err);
            setProduct({});
        });
    };

    useEffect(() => {
        const parent = document.querySelector('.tool-container');
        const parentWidth = parent?.clientWidth || 523;
        const parentHeight = parent?.clientHeight;
    
        const newArray = fields.map((attr) => {
            if (attr.isbox === true) {
                const x = parentWidth * (attr.x_position / 100);
                const y = parentHeight * (attr.y_position / 100);
                return {
                    label: attr?.parameter?.parameters_code?.match(/\(([^)]+)\)/)?.[1] || '',
                    uuid: attr.parameter.uuid,
                    unit: attr.uom_value,
                    x: attr.x_position,
                    y: attr.y_position,
                    defaultX: x,
                    defaultY: y
                };
            }
            return null;
        }).filter(item => item !== null);
    
        setTotalAttr(newArray);
    }, [fields]);      

    function fetchToolTypes() {
        get_tooltype_api().then((res) => {

            setToolTypeList({ ...toolTypeList, loading: false, data: res.data.data });
        }, (err) => {

            console.log("err", err);
            setToolTypeList({ ...toolTypeList, loading: false, data: [] });
        });
    }

    function fetchToolCats() {
        get_tool_cat_api().then((res) => {

            setToolCatList({ ...toolCatList, loading: false, data: res.data.data });
        }, (err) => {
            setToolCatList({ ...toolCatList, loading: false, data: [] });
        });
    }

    function fetchToolSubCats() {
        get_tool_sub_cat_api().then((res) => {
            //console.log("res", res);

            setToolSubCatList({ ...toolSubCatList, loading: false, data: res.data.data });
        }, (err) => {

            console.log("err", err);
            setToolCatList({ ...toolSubCatList, loading: false, data: [] });
        });
    }

    function fetchToolParameters() {
        get_tooling_parameter_api().then((res) => {
            //console.log("res", res.data.data);

            setToolParameterList({ ...toolParameterList, loading: false, data: res.data.data });
        }, (err) => {
            setToolCatList({ ...toolParameterList, loading: false, data: [] });
        });
    }

    useEffect(() => {
        if (selectedtool_type && selectedtool_type?.uuid) {
            get_tool_category_by_tool_type(selectedtool_type.uuid)
        }
        else {
            setToolCategoryData({ data: [], loading: false });
        }

    }, [selectedtool_type])

    useEffect(() => {
        if (selectedtool_category && selectedtool_category?.uuid) {
            get_tool_subcategory_by_tool_category(selectedtool_category.uuid)
        }
        else {
            setToolSubCategoryData({ data: [], loading: false });
        }

    }, [selectedtool_category])

    function get_tool_category_by_tool_type(uuid) {
        //console.log("uuid",uuid)
        //console.log("toolCatList",toolCatList)
        let arr = toolCatList.data.filter((item) => item.tool_type == uuid);
        //console.log("arr", arr)
        setToolCategoryData({ data: arr, loading: false })
    }

    function get_tool_subcategory_by_tool_category(uuid) {
        let arr = toolSubCatList.data.filter((item) => item.tool_category == uuid);
        setToolSubCategoryData({ data: arr, loading: false })
    }

    async function fileupload(uploadImage, uploadImageName, previousImage = "") {
        if (previousImage == "") {
            ReactS3Client.uploadFile(uploadImage, uploadImageName)
                .then(data1 => { 
                    //console.log("data1", data1)
                })
        } else {
            ReactS3Client
                .deleteFile(previousImage)
                .then((response) => {
                    ReactS3Client.uploadFile(uploadImage, uploadImageName)
                        .then(data1 => { })
                })
                .catch(err => console.error(err));
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        const fileExtension = selectedFile.name.split('.').pop(); 
        const timeStampedFileName = `${Date.now()}.${fileExtension}`;
        setFileName(timeStampedFileName);
        setFileUrl(URL.createObjectURL(selectedFile));
    };

    const onSubmit = async (data) => {
        let productImage = '';

        // Upload the file to S3
        if(file)
        {
            await fileupload(file, fileName);
            productImage = fileName;
        }

        const payload = {
            product_id: uuid,
            tool_type: selectedtool_type,
            tool_category: selectedtool_category,
            tool_subcategory: selectedtool_subcategory,
            product_image: `${config.S3URL}product/${productImage}`,
            attributes: selected_attributes.map((attr, index) => {
                const row = totalAttr.find(r => r.uuid == attr.parameter.uuid);
                let x = "";
                let y = "";
                if(row)
                {
                    x = row["x"];
                    y = row["y"]
                }
                return{
                    uuid: attr.uuid,
                    parameter: attr.parameter,
                    uom: attr.uom,
                    uom_value: attr.uom_value,
                    tol_positive: attr.tol_positive,
                    tol_negative: attr.tol_negative,
                    x_position: x,
                    y_position: y
                }}),
        };

        //console.log("payload", payload)

        try {
            if(uuid && uuid != "")
            {
                update_product(payload).then((res) => {
                    toast.success('Product updated successfully!');
                    // Handle success (e.g., redirect or reset form)
                    // reset();
                    // setFile(null);
                    // setAttributes([]);
                    // setTotalAttr([]);
                    navigate(`/app/product/${uuid}`, { replace: true });
                }, (err) => {
                    console.log("err", err);
                    toast.error(err.response.data.message);
                });
            }else{
                create_product(payload).then((res) => {
                    toast.success('Product created successfully!');
                    // Handle success (e.g., redirect or reset form)
                    reset();
                    setFile(null);
                    setAttributes([]);
                    setTotalAttr([]);
                    navigate('/app/products');
                }, (err) => {
                    console.log("err", err);
                    toast.error(err.response.data.message);
                });
            }
        } catch (error) {
            //toast.error('An error occurred');
            console.error('Submit Error:', error);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Modal
                show={visible}
                onHide={() => setVisible(false)}
                size={"lg"}
                backdrop="static"
            >
                <ModalHeader className="modalHeader" closeButton>
                    <Modal.Title>
                        Add Attribute
                    </Modal.Title>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label" htmlFor="user_type">
                                Parameter
                                <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name={`parameter`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id={`parameter`}
                                            labelKey="parameters_code"
                                            multiple={false}
                                            options={toolParameterList.data}
                                            loading={toolParameterList.loading}
                                            placeholder="Select Parameter"
                                            onChange={(selected) => selectParameter(selected, onChange)}  // Pass onChange
                                            selected={attribute.parameter.parameters_code !== "" ? [attribute.parameter] : []}
                                            clearButton
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label" htmlFor="user_type">
                                UOM
                                <span className="text-danger">*</span>
                                </label>
                                <input
                                    //name={`attribute.${index}.uom_value`}
                                    type="text"
                                    id="uom"
                                    value={attribute?.uom_value}
                                    disabled
                                    className={`form-control ${errors.tol_positive && "is-invalid"}`}
                                    //{...register(`attribute.${index}.uom_value`)}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label" htmlFor="user_type">
                                + Tolerance
                                <span className="text-danger">*</span>
                                </label>
                                <input
                                    name={`tol_positive`}
                                    type="text"
                                    id="uom"
                                    className={`form-control ${errors.tol_positive && "is-invalid"}`}
                                    onKeyUp={modalInputChange}
                                    //{...register(`attribute.${index}.uom_value`)}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label" htmlFor="user_type">
                                - Tolerance
                                <span className="text-danger">*</span>
                                </label>
                                <input
                                    name={`tol_negative`}
                                    type="text"
                                    id="uom"
                                    className={`form-control ${errors.tol_negative && "is-invalid"}`}
                                    onKeyUp={modalInputChange}
                                    //{...register(`attribute.${index}.uom_value`)}
                                />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label" htmlFor="user_type">
                                Box Needed
                                <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="isbox"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Typeahead
                                            id="static-typeahead"
                                            labelKey="label"
                                            options={[
                                                { label: "Yes", value: true },
                                                { label: "No", value: false }
                                            ]}
                                            placeholder="Box Needed"
                                            onChange={(selected) => {
                                                //console.log("selected", selected)
                                                // Take the first selected item
                                                const newAttribute = {...attribute};
                                                newAttribute["isbox"] = selected[0].value;
                                                setAttribute(newAttribute);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-yellow px-3"
                        type="submit"
                        onClick={addAttribute}
                    >
                        Save
                    </button>
                </ModalFooter>
            </Modal>

            <div className='tool_type'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className="page-heading mb-0 fw600">
                        Product
                    </h1>
                </div>
                <div className='card mt-3 border-0'>

                    <div className="container-fluid p-4">

                        {/* Product Selection and Submit Button in the Same Row */}
                        <div className="row mb-4">
                            <div className="col-12 col-md-2 mb-3">
                                <label>Product Type</label>
                                <Controller
                                    name={`tool_type`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead"
                                            labelKey="tool_type_name"
                                            options={toolTypeList.data}
                                            loading={toolTypeList.loading}
                                            placeholder="Select Tool Type"
                                            onChange={(selected) => {
                                                onChange(selected[0] || null);
                                            }}
                                            onBlur={onBlur}
                                            selected={value ? [value] : []} 
                                            //isInvalid={!errors.tool_type} 
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-2 mb-3">
                                <label>Product Category</label>
                                <Controller
                                    name={`tool_category`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead"
                                            labelKey="description"
                                            multiple={false}
                                            options={toolCategoryData.data}
                                            loading={toolCategoryData.loading}
                                            placeholder="Select Tool Category"
                                            onChange={(selected) => {
                                                onChange(selected[0] || null);
                                            }}
                                            onBlur={onBlur}
                                            selected={value ? [value] : []} 
                                            //isInvalid={!!errors.tool_category} 
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-2 mb-3">
                                <label>Product Sub-Category</label>
                                <Controller
                                    name={`tool_subcategory`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead"
                                            labelKey="description"
                                            multiple={false}
                                            options={toolSubCategoryData.data}
                                            loading={toolSubCategoryData.loading}
                                            placeholder="Select Tool Sub Category"
                                            onChange={(selected) => {
                                                onChange(selected[0] || null);
                                            }}
                                            onBlur={onBlur}
                                            selected={value ? [value] : []} 
                                            //isInvalid={!!errors.tool_subcategory} 
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-2 mb-3">
                                <label>Product Code</label>
                                <input
                                    type="text"
                                    disabled
                                    id="product_code"
                                    className={`form-control ${errors.product_code && "is-invalid"}`}
                                    placeholder="Product Code"
                                    {...register("product_code")}
                                />
                            </div>
                            <div className="col-12 col-md-2 mb-3">
                                <label>Upload Product</label>
                                <input type='file' className='form-control' onChange={handleFileChange} />
                            </div>
                            {fileUrl && (
                                <div className="col-12 col-md-2 d-flex align-items-end mb-3">
                                    <button className="btn btn-danger w-100" onClick={() => setVisible(true)}>Add Attribute</button>
                                </div>
                            )}
                        </div>

                        {/* Image Display */}
                        {fileUrl && (
                            <div className="row mb-4 justify-content-center">
                                <div className="col-12 col-md-6 text-center">
                                    <div className="tool-container" style={{ background: `url(${fileUrl}) no-repeat center/contain` }}>
                                        {/* {console.log("totalAttr", totalAttr)} */}
                                        {totalAttr
                                            //.filter(spec => spec.isbox == true)
                                            .map((spec, index) => (
                                            <Draggable 
                                                defaultPosition={{ x: spec.defaultX, y: spec.defaultY }}
                                                //bounds="parent" 
                                                key={spec.uuid} 
                                                onStop={(e, data) => handleStop(index, e, data)}>
                                                <div
                                                    key={spec.label}
                                                    className="spec"
                                                    style={{
                                                        //left: `${spec.x}%`,
                                                        //top: `${spec.y}%`,
                                                        //transform: 'translate(190px, 100px)',
                                                        transformOrigin: 'top left',
                                                    }}
                                                >
                                                    <label htmlFor={spec.label} className="unit">
                                                        <input
                                                            placeholder={spec.label}
                                                            name={spec.label}
                                                            //defaultValue={spec.value ?? ''}
                                                            type="text"
                                                            form="tool-form"
                                                        />
                                                        {/* {spec.unit} */}
                                                    </label>
                                                </div>
                                            </Draggable>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <div className="tool-container"  style={{ background: `url(${fileUrl}) no-repeat center/contain` }}>
                            <ToolSpecs initialSpecs={totalAttr} />
                        </div> */}

                        {/* Product Attributes */}
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Parameter</th>
                                            <th>UOM</th>
                                            {/* <th>TOL-Applicable</th> */}
                                            <th>+ Tolerance</th>
                                            <th>- Tolerance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {console.log("uuid", fields)} */}
                                        {fields.map((attribute, index) => (
                                            <tr key={attribute.uuid || index}>
                                                <td>
                                                    <input type="hidden" name={`attribute.${index}.uuid`} value="" />
                                                    <input type="hidden" name={`attribute.${index}.uom`} />
                                                    <Controller
                                                        name={`attribute.${index}.parameter`}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Typeahead
                                                                id={`parameter-${index}`}
                                                                labelKey="parameters_code"
                                                                multiple={false}
                                                                options={toolParameterList.data}
                                                                loading={toolParameterList.loading}
                                                                placeholder="Select Parameter"
                                                                {...attribute}
                                                                onChange={(selected) => {
                                                                    const selectedObject = selected[0] || null;

                                                                    // Check if the parameter is already selected in another row
                                                                    const checkIndex = fields.findIndex(x => x.parameter?.uuid === selectedObject?.uuid && x !== fields[index]);

                                                                    if (checkIndex === -1) {
                                                                        // Set the selected parameter and its related uom values
                                                                        setValue(`attribute.${index}.parameter`, selectedObject);
                                                                        if (selectedObject && selectedObject.uomdata) {
                                                                            setValue(`attribute.${index}.uom`, selectedObject.uomdata);
                                                                            setValue(`attribute.${index}.uom_value`, selectedObject.uomdata.uom);
                                                                        } else {
                                                                            // Clear uom if no valid parameter selected
                                                                            setValue(`attribute.${index}.uom`, null);
                                                                            setValue(`attribute.${index}.uom_value`, '');
                                                                        }
                                                                    } else {
                                                                        // Show error if the parameter already exists
                                                                        toast.error('Parameter already exists');
                                                                        setValue(`attribute.${index}.parameter`, null);  // Reset the value
                                                                    }
                                                                }}
                                                                selected={value ? [value] : []}
                                                                />
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`attribute.${index}.uom_value`}
                                                        type="text"
                                                        id="uom"
                                                        disabled
                                                        className={`form-control ${errors.tol_positive && "is-invalid"}`}
                                                        {...register(`attribute.${index}.uom_value`)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`attribute.${index}.tol_positive`}
                                                        type="text"
                                                        id="+tol"
                                                        className={`form-control ${errors.tol_positive && "is-invalid"}`}
                                                        placeholder="0.0000"
                                                        {...register(`attribute.${index}.tol_positive`)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`attribute.${index}.tol_negative`}
                                                        type="text"
                                                        id="-tol"
                                                        className={`form-control ${errors.tol_negative && "is-invalid"}`}
                                                        placeholder="0.0000"
                                                        {...register(`attribute.${index}.tol_negative`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Eye
                                                        style={attribute.isbox ? { color: 'red' } : {}}
                                                        className='m-2' 
                                                        onClick={() => generateBox(index)} 
                                                    />
                                                    <Trash
                                                        style={{ cursor: 'pointer' }}
                                                        color="red"
                                                        size={20}
                                                        onClick={() => removeAttribute(index, attribute)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        {/* Repeat for other attributes */}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Save Button */}
                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-danger" onClick={onSubmit}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default Create