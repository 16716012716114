import React, { useEffect, useState } from "react";
import {
  get_tool_type_mapping_api,
  create_tool_type_mapping_api,
  get_tool_sub_cat_api,
  update_tool_type_mapping_api,
  delete_tool_type_mapping_api,
  get_tool_cat_api,
  get_tooltype_api,
} from "../../Common/API/index";
import { Menu, Plus, Pencil, Trash2 } from "lucide-react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from "react-bootstrap/Alert";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object({
    tool_type: yup
      .array()
      .min(1, "Select tool type")
      .required("Select tool type"),
    tool_category: yup
      .array()
      .min(1, "Select tool category")
      .required("Select tool category"),
    tool_subcategory: yup
      .array()
      .min(1, "Select tool subcategory")
      .required("Select tool subcategory"),
  })
  .required();

function ToolMapping() {
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState({ state: "create", data: {} });
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
    maxPage: 0,
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [toolTypeList, setToolTypeList] = useState({ data: [], loading: true });
  const [toolCatList, setToolCatList] = useState({ data: [], loading: true });
  const [toolSubCatList, setToolSubCatlist] = useState({
    data: [],
    loading: true,
  });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        description: null,
      },
    }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    console.log(data);

    if (action.state == "create") {
      const payload = {
        tool_type: data.tool_type[0].uuid,
        tool_category: data.tool_category[0].uuid,
        tool_subcategory: data.tool_subcategory[0].uuid,
      };
      console.log("payload", payload)


      create_tool_type_mapping_api(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Tool type mapped  successfully");
          setVisible(false);
          reset();
          fetchToolTypeMappingList(1, entriesPerPage);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message);
        }
      );
    } else {
      const payload = {
        tool_type: data.tool_type[0].uuid,
        tool_category: data.tool_category[0].uuid,
        tool_subcategory: data.tool_subcategory[0].uuid,
        uuid: action.data.uuid,
      };

      update_tool_type_mapping_api(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Tool type mapping updated successfully");
          setVisible(false);
          reset();
          fetchToolTypeMappingList(1, entriesPerPage);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message);
        }
      );
    }
  };

  useEffect(() => {
    fetchToolTypes();
    fetchToolCats();
    fetchToolSubCats();
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchToolTypeMappingList(1, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, []);

  function fetchToolTypes() {
    get_tooltype_api().then(
      (res) => {
        console.log("res", res);

        setToolTypeList({
          ...toolTypeList,
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        console.log("err", err);
        setToolTypeList({ ...toolTypeList, loading: false, data: [] });
      }
    );
  }

  function fetchToolCats() {
    get_tool_cat_api().then(
      (res) => {
        console.log("res", res);

        setToolCatList({ ...toolCatList, loading: false, data: res.data.data });
      },
      (err) => {
        console.log("err", err);
        setToolCatList({ ...toolCatList, loading: false, data: [] });
      }
    );
  }

  function fetchToolSubCats() {
    get_tool_sub_cat_api().then(
      (res) => {
        console.log("res", res);

        setToolSubCatlist({
          ...toolCatList,
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        console.log("err", err);
        setToolSubCatlist({ ...toolCatList, loading: false, data: [] });
      }
    );
  }

  function fetchToolTypeMappingList(pg, epp) {
    setDataList({ ...dataList, loading: true });

    const payload = {
      limit: epp,
      page: pg,
    };

    get_tool_type_mapping_api(payload).then(
      (res) => {
        console.log("res", res);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
          maxPage: calMaxPage(res.data.total),
        });
      },
      (err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const delToolTypeMapping = (val) => {
    Swal.fire({
      title: "Are you sure to delete this record ?",
      text: val.tool_subcategory_code,
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        delete_tool_type_mapping_api({ tool_type_mapping_id: val.uuid }).then(
          (res) => {
            toast.success(`Record deleted successfully`);

            if (dataList.data.length == 1 && page > 1) {
              fetchToolTypeMappingList(page - 1, entriesPerPage);

              setPage(page - 1);
              setSrNo((prevC) =>
                page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
              );
            } else {
              fetchToolTypeMappingList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message);
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size={"lg"}
        backdrop="static"
      >
        <ModalHeader className="modalHeader" closeButton>
          <Modal.Title>
            {" "}
            {`${action.state == "create" ? "Create" : "Update"
              } Tool Type Mapping`}
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="user_type">
                  Tool Type
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`tool_type`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="tool_type_name"
                      multiple={false}
                      options={toolTypeList.data}
                      loading={toolTypeList.loading}
                      placeholder="Select Tool Type"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.tool_type ? true : false}
                    />
                  )}
                />

                {errors.tool_type && (
                  <span className="text-danger">
                    {errors.tool_type?.message}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="tool_category">
                  Tool Category
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`tool_category`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="tool_category_code"
                      multiple={false}
                      options={toolCatList.data}
                      loading={toolCatList.loading}
                      placeholder="Select Tool Category"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.tool_category ? true : false}
                    />
                  )}
                />

                {errors.tool_category && (
                  <span className="text-danger">
                    {errors.tool_category?.message}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="tool_subcategory">
                  Tool Sub Category
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`tool_subcategory`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="tool_subcategory_code"
                      multiple={false}
                      options={toolSubCatList.data}
                      loading={toolSubCatList.loading}
                      placeholder="Select Tool Sub Category"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.tool_subcategory ? true : false}
                    />
                  )}
                />

                {errors.tool_subcategory && (
                  <span className="text-danger">
                    {errors.tool_subcategory?.message}
                  </span>
                )}
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-yellow px-3"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>

      <div className="tool_type">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading mb-0 fw600">Tool Type Mapping</h1>
          <div>
            <span
              className="cursor_pointer px-3 me-0 me-md-2"
              onClick={() => {
                // openNav();
              }}
            >
              <Menu size={18} className="me-1 p-0" />

              {"Filter Menu"}
            </span>
            <button
              onClick={() => {
                setVisible(true);
                setAction({ ...action, state: "create", data: {} });
                reset();
              }}
              className="btn btn-blue"
            >
              <Plus size={18} className="me-1 p-0" /> Map Tool Type
            </button>
          </div>
        </div>

        <div className="card mt-3 border-0">
          <div className="card-body">
            <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
              <p className="mb-0">
                Show
                <select
                  className="mx-1"
                  defaultValue={"10"}
                  onChange={(e) => {
                    setEntriesPerPage(e.target.value);
                    fetchToolTypeMappingList(page, e.target.value);
                  }}
                >
                  <option value={"10"}>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                Entries
              </p>
            </div>

            {dataList.loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : dataList.data.length == 0 ? (
              <Alert className="text-center" variant={"danger"}>
                No Tool Type Mapping Found
              </Alert>
            ) : (
              <div className="table-responsive">
                <table className="table mt-2 table-striped">
                  <thead className="table-grey">
                    <tr className="">
                      <th scope="col">{"SrNo"}</th>

                      <th scope="col">{"Tool Type"}</th>
                      <th scope="col">{"Tool Category"}</th>
                      <th scope="col">{"Tool Sub Category"}</th>

                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.data.map((val, i) => (
                      <tr key={val.uuid}>
                        <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                        <td>
                          {val.tool_type_data == null
                            ? "NA"
                            : val.tool_type_data.tool_type_name}
                        </td>
                        <td>
                          {val.tool_category_data == null
                            ? "NA"
                            : val.tool_category_data.tool_category_code}
                        </td>
                        <td>
                          {val.tool_subcategory_data == null
                            ? "NA"
                            : val.tool_subcategory_data.tool_subcategory_code}
                        </td>

                        <td>
                          <>
                            <Tooltip id={"edit-tooltip" + i} place="top" />
                            <div
                              data-tooltip-id={"edit-tooltip" + i}
                              data-tooltip-content={"Edit Tool Type Mapping"}
                              className="editIcon m-1 cursor_pointer"
                              onClick={() => {
                                setVisible(true);
                                setAction({
                                  ...action,
                                  state: "update",
                                  data: val,
                                });
                                setValue("tool_type", [val.tool_type_data], {
                                  shouldValidate: true,
                                });
                                setValue("tool_category", [val.tool_category_data], {
                                  shouldValidate: true,
                                });
                                setValue(
                                  "tool_subcategory",
                                  [val.tool_subcategory_data],
                                  { shouldValidate: true }
                                );
                              }}
                            >
                              <Pencil size={18} color="#fff" />
                            </div>
                          </>
                          <>
                            <Tooltip id={"del-tooltip" + i} place="top" />
                            <div
                              data-tooltip-id={"del-tooltip" + i}
                              data-tooltip-content={"Delete Tool Type Mapping"}
                              className="deleteIcon m-1 cursor_pointer"
                              onClick={() => {
                                delToolTypeMapping(val);
                              }}
                            >
                              <Trash2 size={18} color="#fff" />
                            </div>
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {dataList.loading ? null : dataList.data?.length > 0 ? (
              <div className="mt-4 d-flex justify-content-between">
                <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${dataList.data.length + entriesPerPage * page - entriesPerPage
                  } of ${dataList.totalRecords} entries`}</p>
                <Pagination>
                  <Pagination.Prev
                    disabled={page === 1 ? true : false}
                    onClick={() => {
                      fetchToolTypeMappingList(
                        page - 1,
                        entriesPerPage,
                        parseInt(srNo) - parseInt(entriesPerPage)
                      );
                      setSrNo((prevC) =>
                        page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                      );
                      setPage(page - 1);
                    }}
                  >
                    {"Prev"}
                  </Pagination.Prev>

                  <Pagination.Item active>{page}</Pagination.Item>

                  <Pagination.Next
                    disabled={
                      page === dataList.maxPage ||
                        dataList.maxPage === 0 ||
                        entriesPerPage > dataList.data.length
                        ? true
                        : false
                    }
                    onClick={() => {
                      fetchToolTypeMappingList(
                        page + 1,
                        entriesPerPage,
                        parseInt(srNo) + parseInt(entriesPerPage)
                      );
                      setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                      setPage(page + 1);
                    }}
                  >
                    {"Next"}
                  </Pagination.Next>
                </Pagination>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ToolMapping;
