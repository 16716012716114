import React, { useState, useEffect } from "react";
import Tap from "../../Assets/Images/taps-444500_1920.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Eye, EyeOff } from 'lucide-react';

const schema = yup
    .object()
    .shape({
        username: yup.string().required("Username is required !"),
        password: yup.string().required("Password is required !"),
    })
    .required();

function Login() {

    const [showPassword, setShowPassword] = useState(false);
    const [loginClick, setLoginClick] = useState(false);
    const [isChecked, setIsChecked] = useState(true);


    let navigate = useNavigate();


    const { register, handleSubmit, formState, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    }); // initialise the hook

    const { errors } = formState;


    useEffect(() => {
       
    }, []);

    const onSubmit = (data) => {
        console.log("data", data);
        setLoginClick(true);

        axios.post(`${process.env.REACT_APP_AUTH_URL}`, data,{
            headers: { 
                'x-api-key': `${process.env.REACT_APP_X_API_KEY}` 
            }
        })
            .then((res) => {
                console.log("res", res);
                toast.success("Login Successfully !", {
                    autoClose: 2000,
                });
                localStorage.setItem("mmt_idToken", res.data.response.idToken);
                localStorage.setItem("mmt_accessToken", res.data.response.accessToken);
                localStorage.setItem("mmt_refreshToken", res.data.response.refreshToken);
                setLoginClick(false);
                navigate('/app/machine_category');
            }, (err) => {
                toast.error(err.response.data.message || err.response.data.response, {
                    autoClose: 3000,
                });
                setLoginClick(false);
                console.log("err", err);
            });
    }


    return (
        <React.Fragment>
            <ToastContainer />

            <div className="login_page d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 order-2 order-md-1">
                            <p
                                className=" mt-4 mt-md-0 fw800"
                                style={{ fontSize: "36px" }}
                            >
                                Make My Tool
                            </p>

                            <div className="form_div mx-auto" style={{ maxWidth: "300px" }}>
                                <div className="d-flex justify-content-end mb-4">
                                    {/* <p style={{ fontSize: "24px" }} className="mb-0 ">
                                        MMT
                                    </p> */}
                                    <p className="mb-0 text_gold mt-1">Sign Up</p>
                                </div>
                                <div style={{ width: "300px" }}>
                                    <form>
                                        <div className="col">
                                            <div className="input-group">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Username or Email"
                                                    {...register("username")}
                                                    id="username"
                                                />
                                            </div>
                                            <span
                                                className="text-danger"
                                                style={{ fontSize: "14px" }}
                                            >
                                                {errors.username?.message}
                                            </span>
                                        </div>
                                        <div className="col mt-3">
                                            <div className="input-group">
                                                <input
                                                    className="form-control "
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="Password"
                                                    {...register("password")}
                                                    id="password"
                                                />
                                                <span className="input-group-text">
                                                    {showPassword ? (
                                                        <EyeOff className="cursor_pointer" size={18} onClick={() => setShowPassword(false)} />
                                                    ) : (
                                                        <Eye className="cursor_pointer" size={18} onClick={() => setShowPassword(true)} />
                                                    )}
                                                </span>
                                            </div>
                                            <span
                                                className="text-danger"
                                                style={{ fontSize: "14px" }}
                                            >
                                                {errors.password?.message}
                                            </span>
                                        </div>

                                        <button
                                            className="btn btn-blue w-100 mt-4 "
                                            type="submit"
                                            onClick={handleSubmit(onSubmit)}
                                            style={{ letterSpacing: "1px" }}
                                            disabled={loginClick}
                                        >
                                            LOGIN{" "}
                                            {loginClick ? (
                                                <Spinner animation="border" size="sm" />
                                            ) : null}{" "}
                                        </button>

                                        <p className="mb-4 mt-4" style={{ fontSize: "14px" }}>
                                            Forgot Your Password?{" "}
                                            <span className="text_gold">Click Here</span>
                                        </p>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2 ">
                            <div className="position-relative">
                                <div className="text_over_img ">
                                    Shape Possibilities: Craft Personalized Hardware for Your Unique Needs.
                                    <br />
                                    <p className="pink_rectangle mt-3"></p>
                                </div>
                                <img
                                    id="login_img"
                                    src={Tap}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login