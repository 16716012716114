import {
    v_machine_category, v_machine, v_uom, v_tooltype, v_usertype, v_toolmaterial, v_user, v_tool_category,
    v_tool_subcategory, v_hardness, v_workpiece_material_classification, v_workpiece_material,
    v_tool_type_mapping,
    v_machine_dim_parameter,
    v_tooling_parameter,v_product,
    v_common_attributes,
    v_leads
} from "./APIConstant";
import client1 from "../../Common/API/AxiosInstance";

//machine category apis's 
export const get_machine_category_api = (payload) => {
    return client1.get(v_machine_category, { params: payload })
}

export const create_machine_category_api = (payload) => {
    return client1.post(v_machine_category, payload);
}

export const update_machine_category_api = (payload) => {
    return client1.patch(v_machine_category, payload);
}

export const delete_machine_category_api = (payload) => {
    return client1.delete(v_machine_category, { params: payload });
}

//machine api's 

export const get_machine_api = (payload) => {
    return client1.get(v_machine, { params: payload })
}

export const create_machine_api = (payload) => {
    return client1.post(v_machine, payload);
}

export const update_machine_api = (payload) => {
    return client1.patch(v_machine, payload);
}

export const delete_machine_api = (payload) => {
    return client1.delete(v_machine, { params: payload });
}


//uom api's 
export const get_uom_api = (payload) => {
    return client1.get(v_uom, { params: payload })
}

export const create_uom_api = (payload) => {
    return client1.post(v_uom, payload);
}

export const update_uom_api = (payload) => {
    return client1.patch(v_uom, payload);
}

export const delete_uom_api = (payload) => {
    return client1.delete(v_uom, { params: payload });
}

//tool type api's 
export const get_tooltype_api = (payload) => {
    return client1.get(v_tooltype, { params: payload })
}

export const create_tooltype_api = (payload) => {
    return client1.post(v_tooltype, payload);
}

export const update_tooltype_api = (payload) => {
    return client1.patch(v_tooltype, payload);
}

export const delete_tooltype_api = (payload) => {
    return client1.delete(v_tooltype, { params: payload });
}

// usertype api's 
export const get_usertype_api = (payload) => {
    return client1.get(v_usertype, { params: payload })
}

export const create_usertype_api = (payload) => {
    return client1.post(v_usertype, payload);
}

export const update_usertype_api = (payload) => {
    return client1.patch(v_usertype, payload);
}

export const delete_usertype_api = (payload) => {
    return client1.delete(v_usertype, { params: payload });
}

//tool material api's 
export const get_toolmaterial_api = (payload) => {
    return client1.get(v_toolmaterial, { params: payload })
}

export const create_toolmaterial_api = (payload) => {
    return client1.post(v_toolmaterial, payload);
}

export const update_toolmaterial_api = (payload) => {
    return client1.patch(v_toolmaterial, payload);
}

export const delete_toolmaterial_api = (payload) => {
    return client1.delete(v_toolmaterial, { params: payload });
}

// user api's 
export const get_user_api = (payload) => {
    return client1.get(v_user, { params: payload })
}

export const create_user_api = (payload) => {
    return client1.post(v_user, payload);
}

export const update_user_api = (payload) => {
    return client1.patch(v_user, payload);
}

export const delete_user_api = (payload) => {
    return client1.delete(v_user, { params: payload });
}

//tool category api's 
export const get_tool_cat_api = (payload) => {
    return client1.get(v_tool_category, { params: payload })
}

export const create_tool_cat_api = (payload) => {
    return client1.post(v_tool_category, payload);
}

export const update_tool_cat_api = (payload) => {
    return client1.patch(v_tool_category, payload);
}

export const delete_tool_cat_api = (payload) => {
    return client1.delete(v_tool_category, { params: payload });
}

// tool sub category api's 
export const get_tool_sub_cat_api = (payload) => {
    return client1.get(v_tool_subcategory, { params: payload })
}

export const create_tool_sub_cat_api = (payload) => {
    return client1.post(v_tool_subcategory, payload);
}

export const update_tool_sub_cat_api = (payload) => {
    return client1.patch(v_tool_subcategory, payload);
}

export const delete_tool_sub_cat_api = (payload) => {
    return client1.delete(v_tool_subcategory, { params: payload });
}

// product api's 
export const get_product_api = (payload) => {
    return client1.get(v_product, { params: payload })
}

export const create_product = (payload) => {
    return client1.post(v_product, payload)
}

export const update_product = (payload) => {
    return client1.patch(v_product, payload)
}

export const delete_attribute = (payload) => {
    return client1.delete(`${v_product}/attribute`, { params: payload })
}

// hardness apis' 
export const get_hardness_api = (payload) => {
    return client1.get(v_hardness, { params: payload })
}

export const create_hardness_api = (payload) => {
    return client1.post(v_hardness, payload);
}

export const update_hardness_api = (payload) => {
    return client1.patch(v_hardness, payload);
}

export const delete_hardness_api = (payload) => {
    return client1.delete(v_hardness, { params: payload });
}

// work piece classification 
export const get_work_piece_classi_api = (payload) => {
    return client1.get(v_workpiece_material_classification, { params: payload })
}

export const create_work_piece_classi_api = (payload) => {
    return client1.post(v_workpiece_material_classification, payload);
}

export const update_work_piece_classi_api = (payload) => {
    return client1.patch(v_workpiece_material_classification, payload);
}

export const delete_work_piece_classi_api = (payload) => {
    return client1.delete(v_workpiece_material_classification, { params: payload });
}

//workpiece material 
export const get_workpiece_material_api = (payload) => {
    return client1.get(v_workpiece_material, { params: payload })
}

export const create_workpiece_material_api = (payload) => {
    return client1.post(v_workpiece_material, payload);
}

export const update_workpiece_material_api = (payload) => {
    return client1.patch(v_workpiece_material, payload);
}

export const delete_workpiece_material_api = (payload) => {
    return client1.delete(v_workpiece_material, { params: payload });
}

//tool type mapping 
export const get_tool_type_mapping_api = (payload) => {
    return client1.get(v_tool_type_mapping, { params: payload })
}

export const create_tool_type_mapping_api = (payload) => {
    return client1.post(v_tool_type_mapping, payload);
}

export const update_tool_type_mapping_api = (payload) => {
    return client1.patch(v_tool_type_mapping, payload);
}

export const delete_tool_type_mapping_api = (payload) => {
    return client1.delete(v_tool_type_mapping, { params: payload });
}
// machine dim parameter
export const get_machine_dim_parameter_api = (payload) => {
    return client1.get(v_machine_dim_parameter, { params: payload })
}

export const create_machine_dim_parameter_api = (payload) => {
    return client1.post(v_machine_dim_parameter, payload);
}

export const update_machine_dim_parameter_api = (payload) => {
    return client1.patch(v_machine_dim_parameter, payload);
}

export const delete_machine_dim_parameter_api = (payload) => {
    return client1.delete(v_machine_dim_parameter, { params: payload });
}

//tooling parameter 

export const get_tooling_parameter_api = (payload) => {
    return client1.get(v_tooling_parameter, { params: payload })
}

export const create_tooling_parameter_api = (payload) => {
    return client1.post(v_tooling_parameter, payload);
}

export const update_tooling_parameter_api = (payload) => {
    return client1.patch(v_tooling_parameter, payload);
}

export const delete_tooling_parameter_api = (payload) => {
    return client1.delete(v_tooling_parameter, { params: payload });
}

//Common Attribues api's 

export const get_common_attributes_api = (payload) => {
    return client1.get(v_common_attributes, { params: payload })
}

export const create_common_attributes_api = (payload) => {
    return client1.post(v_common_attributes, payload);
}

export const update_common_attributes_api = (payload) => {
    return client1.patch(v_common_attributes, payload);
}

export const delete_common_attributes_api = (payload) => {
    return client1.delete(v_common_attributes, { params: payload });
}

//Leads api's 

export const get_leads_api = (payload) => {
    return client1.get(v_leads, { params: payload })
}