// import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from './Components/Auth/Login';
import DefaultLayout from './Components/Common/DefaultLayout';
import PageNotFound from './Components/Common/PageNotFound';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Common/Styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function App() {
  const token = localStorage.getItem('mmt_idToken');


  useEffect(() => {
    // console.log("token", token)
  }, []);

  return (
    // <div className="App">
    <BrowserRouter>
      <Routes>
        {/* <Route path={`${process.env.PUBLIC_URL}/demo`} element={<Demo />} /> */}
        {/* <Route path="/" element={<DefaultLayout />} /> */}

        <Route path="/" element={<Navigate to={token ? "/app" : "/login"} />} />

        <Route path="/login" element={!token ? <Login /> : <Navigate to={"/app"} />} />
        <Route path="*" element={<PageNotFound to="/404" />} />
        <Route path="/404" element={<PageNotFound />} />

        <Route path="/app/*" element={<DefaultLayout />} />
      </Routes>
    </BrowserRouter>
    // </div>
  );
}

export default App;
