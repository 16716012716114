import React, { useEffect, useState } from 'react'
import {
    get_work_piece_classi_api, create_work_piece_classi_api,
    update_work_piece_classi_api, delete_work_piece_classi_api
} from "../../Common/API/index";
import { Menu, Plus, Pencil, Trash2 } from 'lucide-react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Pagination } from 'react-bootstrap';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from "react-tooltip";
import Swal from 'sweetalert2'
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from 'react-bootstrap/Alert';

const schema = yup
    .object({
        iso_classification: yup.string().
            max(200, "Maximum 200 characters allowed")
            .required("Enter iso classification"),
        description: yup.string().
            max(500, "Maximum 500 characters allowed")
            .notRequired("Enter description"),
    })
    .required()

function WorkPieceMaterialClassfication() {
    const [visible, setVisible] = useState(false);
    const [action, setAction] = useState({ state: "create", data: {} });
    const [dataList, setDataList] = useState({ data: [], loading: true, totalRecords: '', maxPage: 0 });
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            description: null
        },
    })
    const onSubmit = (data) => {
        console.log(data);

        if (action.state == "create") {

            create_work_piece_classi_api(data).then((res) => {
                console.log("res", res);
                toast.success('Work piece material classification created successfully');
                setVisible(false);
                reset();
                fetch_work_Pie_Mat_Class_List(1, entriesPerPage);
            }, (err) => {
                console.log("err", err);
                toast.error(err.response.data.message);
            });
        } else {
            const payload = {
                iso_classification: data.iso_classification,
                description: data.description,
                uuid: action.data.uuid
            };

            update_work_piece_classi_api(payload).then((res) => {
                console.log("res", res);
                toast.success('Work piece material classification updated successfully');
                setVisible(false);
                reset();
                fetch_work_Pie_Mat_Class_List(1, entriesPerPage);
            }, (err) => {
                console.log("err", err);
                toast.error(err.response.data.message);
            });
        }
    }


    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setPage(1);
            fetch_work_Pie_Mat_Class_List(1, entriesPerPage);
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, []);



    function fetch_work_Pie_Mat_Class_List(pg, epp) {
        setDataList({ ...dataList, loading: true });

        const payload = {
            limit: epp,
            page: pg,
        };

        get_work_piece_classi_api(payload).then((res) => {
            console.log("res", res);
            setDataList({
                ...dataList, loading: false, data: res.data.data,
                totalRecords: res.data.total, maxPage: calMaxPage(res.data.total)
            });


        }, (err) => {
            console.log("err", err);
            toast.error(err?.response?.data?.message);
            setDataList({ ...dataList, loading: false, data: [] });
        });
    }


    const delMachinCat = (val) => {
        Swal.fire({
            title: "Are you sure to delete",
            text: val.iso_classification,
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                delete_work_piece_classi_api({ workpiece_material_classification_id: val.uuid }).then((res) => {
                    toast.success(`${val.iso_classification} deleted successfully`);

                    if (dataList.data.length == 1 && page > 1) {
                        fetch_work_Pie_Mat_Class_List(page - 1, entriesPerPage);

                        setPage(page - 1);
                        setSrNo((prevC) => page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage));
                    } else {
                        fetch_work_Pie_Mat_Class_List(page, entriesPerPage);
                    }
                }, (err) => {
                    toast.error(err.response.data.message);
                });
            }
        });
    };


    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />



            <Modal show={visible} onHide={() => setVisible(false)} size={"lg"} backdrop="static">
                <ModalHeader className='modalHeader' closeButton>
                    <Modal.Title> {`${action.state == "create" ? "Create" : "Update"} Work Piece Material Classification`}</Modal.Title>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className='row'>

                            <div className='col-12 col-md-6 mb-3'>

                                <label
                                    className="form-label"
                                    htmlFor="iso_classification">ISO Classification
                                    <span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="iso_classification"
                                    className={`form-control ${errors.iso_classification && "is-invalid"}`}
                                    placeholder="Enter ISO classification"
                                    {...register("iso_classification")}
                                />

                                {errors.iso_classification &&
                                    <span className='text-danger'>{errors.iso_classification?.message}</span>
                                }


                            </div>
                            <div className='col-12 col-md-6 mb-3'>

                                <label
                                    className="form-label"
                                    htmlFor="description">Description

                                </label>
                                <input
                                    type="text"
                                    id="description"
                                    className={`form-control ${errors.description && "is-invalid"}`}
                                    placeholder="Enter description"
                                    {...register("description")}
                                />

                                {errors.description &&
                                    <span className='text-danger'>{errors.description?.message}</span>
                                }


                            </div>
                        </div>


                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className='btn btn-yellow px-3'
                        type='submit' onClick={handleSubmit(onSubmit)}>Save</button>
                </ModalFooter>
            </Modal>

            <div className='tool_material'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className="page-heading mb-0 fw600">
                        Work Piece Material Classification
                    </h1>
                    <div>
                        <span
                            className="cursor_pointer px-3 me-0 me-md-2"
                            onClick={() => {
                                // openNav();
                            }}
                        >
                            <Menu size={18} className='me-1 p-0' />

                            {"Filter Menu"}
                        </span>
                        <button
                            onClick={() => {
                                setVisible(true);
                                setAction({ ...action, state: "create", data: {} });
                                reset();
                            }}
                            className='btn btn-blue'>
                            <Plus size={18} className='me-1 p-0' /> Create Work Piece Material Classification
                        </button>
                    </div>
                </div>


                <div className='card mt-3 border-0'>
                    <div className='card-body'>
                        <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
                            <p className="mb-0">
                                Show
                                <select
                                    className="mx-1"
                                    defaultValue={"10"}
                                    onChange={(e) => {
                                        setEntriesPerPage(e.target.value);
                                        fetch_work_Pie_Mat_Class_List(page, e.target.value);
                                    }}
                                >
                                    <option value={"10"}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                Entries
                            </p>

                        </div>


                        {dataList.loading ?
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            dataList.data.length == 0 ?
                                <Alert className='text-center' variant={"danger"}>
                                    No Work Piece Material Classification Found
                                </Alert>
                                :
                                <div
                                    className="table-responsive">
                                    <table className="table mt-2 table-striped">
                                        <thead className="table-grey">
                                            <tr className="">
                                                <th scope="col">{"SrNo"}</th>

                                                <th scope="col">
                                                    {"ISO Classification"}
                                                </th>
                                                <th scope="col">
                                                    {"Description"}
                                                </th>

                                                <th scope="col">
                                                    {"Action"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataList.data.map((val, i) => (
                                                    <tr key={val.uuid}>
                                                        <td>
                                                            {parseInt(i) + 1 + parseInt(srNo)}
                                                        </td>
                                                        <td>{val.iso_classification == null ? "NA" : val.iso_classification}</td>
                                                        <td>{val.description == null ? "NA" : val.description}</td>
                                                        <td>
                                                            <>
                                                                <Tooltip
                                                                    id={"edit-tooltip" + i}
                                                                    place="top"
                                                                />
                                                                <div
                                                                    data-tooltip-id={"edit-tooltip" + i}
                                                                    data-tooltip-content={"Edit Work Piece Material Classification"}
                                                                    className="editIcon m-1 cursor_pointer"
                                                                    onClick={() => {
                                                                        setVisible(true);
                                                                        setAction({ ...action, state: "update", data: val });
                                                                        setValue("iso_classification", val.iso_classification, { shouldValidate: true });
                                                                        setValue("description", val.description, { shouldValidate: true });
                                                                    }}
                                                                >
                                                                    <Pencil size={18} color='#fff' />
                                                                </div>
                                                            </>
                                                            <>
                                                                <Tooltip
                                                                    id={"del-tooltip" + i}
                                                                    place="top"
                                                                />
                                                                <div
                                                                    data-tooltip-id={"del-tooltip" + i}
                                                                    data-tooltip-content={"Delete Work Piece Material Classification"}
                                                                    className="deleteIcon m-1 cursor_pointer"
                                                                    onClick={() => {
                                                                        delMachinCat(val)
                                                                    }}
                                                                >
                                                                    <Trash2 size={18} color='#fff' />
                                                                </div>
                                                            </>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                        }



                        {dataList.loading ? null : dataList.data?.length > 0 ? (
                            <div className="mt-4 d-flex justify-content-between">
                                <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${dataList.data.length +
                                    entriesPerPage * page -
                                    entriesPerPage
                                    } of ${dataList.totalRecords} entries`}</p>
                                <Pagination>
                                    <Pagination.Prev
                                        disabled={page === 1 ? true : false}
                                        onClick={() => {
                                            fetch_work_Pie_Mat_Class_List(
                                                page - 1,
                                                entriesPerPage,
                                                parseInt(srNo) - parseInt(entriesPerPage)
                                            );
                                            setSrNo((prevC) =>
                                                page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                                            );
                                            setPage(page - 1);
                                        }}
                                    >
                                        {"Prev"}
                                    </Pagination.Prev>

                                    <Pagination.Item active>{page}</Pagination.Item>

                                    <Pagination.Next
                                        disabled={
                                            page === dataList.maxPage ||
                                                dataList.maxPage === 0 ||
                                                entriesPerPage > dataList.data.length
                                                ? true
                                                : false
                                        }
                                        onClick={() => {

                                            fetch_work_Pie_Mat_Class_List(
                                                page + 1,
                                                entriesPerPage,
                                                parseInt(srNo) + parseInt(entriesPerPage)
                                            );
                                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                                            setPage(page + 1);
                                        }}
                                    >
                                        {"Next"}
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default WorkPieceMaterialClassfication