import React, { useEffect, useState } from 'react'
import {
    get_leads_api
} from "../../Common/API/index";
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from "react-bootstrap/Alert";

import { Menu,  } from 'lucide-react';
import {  Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
    faBan,
    faFilter,

  } from "@fortawesome/free-solid-svg-icons";

function Leads() {
    const [dataList, setDataList] = useState({ data: [], loading: true, totalRecords: '', maxPage: 0 });
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [filterConfig, setFilterConfig] = useState({
        search: "",
      });
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    
    function clearFilter() {
        setFilterConfig({
          ...filterConfig,
          search: "",
        });
        setSrNo(0);
        setPage(1);
      }

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setPage(1);
            fetchleadList(1, entriesPerPage);
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [filterConfig]);



    function fetchleadList(pg, epp) {
        setDataList({ ...dataList, loading: true });

        const payload = {
            limit: epp,
            page: pg,
        };
        if (filterConfig.search !== "") {
            // payload.productName_list = filterConfig.productName;
            payload.search = filterConfig.search.trim();
          } else {
            delete payload.search;
          }
        get_leads_api(payload).then((res) => {
            console.log("res", res);
            setDataList({
                ...dataList, loading: false, data: res.data.data,
                totalRecords: res.data.total, maxPage: calMaxPage(res.data.total)
            });


        }, (err) => {
            console.log("err", err);
            setDataList({ ...dataList, loading: false, data: [] });
        });
    }
    function openNav() {
        const sidebar = document.getElementById("mySidebar");
        const mainDiv = document.getElementById("root_div_main");
    
        if (sidebar.style.width === "320px") {
          // Sidebar is open, so close it
          sidebar.style.width = "0";
          mainDiv.style.marginLeft = "0";
          sidebar.style.overflowX = "hidden";
        } else {
          // Sidebar is closed, so open it
          sidebar.style.width = "320px";
          mainDiv.style.marginLeft = "320px";
          setTimeout(() => {
            sidebar.style.overflowX = "visible";
          }, 200);
        }
        
      }
    
      function closeNav() {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("root_div_main").style.marginLeft = "0";
    
        document.getElementById("mySidebar").style.overflowX = "hidden";
      }



    return (
        <React.Fragment>
            



            <div className="min-vh-100 " id="root_div_main">
            <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Search"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by customer name"
                value={filterConfig.search}
                onChange={(val) => {
                  const inputValue = val.target.value; // Trim leading and trailing whitespaces
                  //  console.log("inputValue",inputValue)
                  if (!inputValue.trim()) {
                    // console.log("inputValue2",inputValue)
                    if (filterConfig.search.length == 1) {
                      // console.log("inputValue3",inputValue)
                      setFilterConfig({ ...filterConfig, search: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, search: "" });
                    }
                  } else {
                    // console.log("inputValue4",inputValue)
                    setFilterConfig({
                      ...filterConfig,
                      search: inputValue,
                    });
                  }
                }}
              />
            </div>
            
         
           
            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {"Clear Filter"}
              </button>
            </div>
          </div>
        </div>
            <div className='uom_class'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className="page-heading mb-0 fw600">
                        Leads
                    </h1>
                    <div>
                        <span
                            className="cursor_pointer px-3 me-0 me-md-2"
                            onClick={() => {
                                openNav();
                            }}
                        >
                            <Menu size={18} className='me-1 p-0' />

                            {"Filter Menu"}
                        </span>
                        
                    </div>
                </div>


                <div className='card mt-3 border-0'>
                    <div className='card-body'>
                        <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
                            <p className="mb-0">
                                Show
                                <select
                                    className="mx-1"
                                    defaultValue={"10"}
                                    onChange={(e) => {
                                        setEntriesPerPage(e.target.value);
                                        fetchleadList(page, e.target.value);
                                    }}
                                >
                                    <option value={"10"}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                Entries
                            </p>

                        </div>


                        {dataList.loading ?
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            dataList.data.length == 0 ?
                                <Alert className='text-center' variant={"danger"}>
                                    No Leads Found
                                </Alert>
                                :
                                <div
                                    className="table-responsive">
                                    <table className="table mt-2 table-striped">
                                        <thead className="table-grey">
                                            <tr className="">
                                                <th scope="col">{"SrNo"}</th>
                                                <th scope="col">
                                                    {"Lead ID"}
                                                </th>
                                                <th scope="col">
                                                    {"Email"}
                                                </th>
                                                <th scope="col">
                                                    {"Customer Name"}
                                                </th>

                                                <th scope="col">
                                                    {"GST Number"}
                                                </th>
                                                <th scope="col">
                                                    {"Address"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataList.data.map((val, i) => (
                                                    <tr key={val.uuid}>
                                                        <td>
                                                            {parseInt(i) + 1 + parseInt(srNo)}
                                                        </td>
                                                        <td>{val.lead_id}</td>
                                                        <td>{val.email == null ? "NA" : val.email}</td>
                                                        <td>{val.customer_name == null ? "NA" : val.customer_name}</td>
                                                        <td>{val.gst_number == null ? "NA" : val.gst_number}</td>
                                                        <td>{val.address == null ? "NA" : val.address}</td>
                                                        
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                        }



                        {dataList.loading ? null : dataList.data?.length > 0 ? (
                            <div className="mt-4 d-flex justify-content-between">
                                <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${dataList.data.length +
                                    entriesPerPage * page -
                                    entriesPerPage
                                    } of ${dataList.totalRecords} entries`}</p>
                                <Pagination>
                                    <Pagination.Prev
                                        disabled={page === 1 ? true : false}
                                        onClick={() => {
                                            fetchleadList(
                                                page - 1,
                                                entriesPerPage,
                                                parseInt(srNo) - parseInt(entriesPerPage)
                                            );
                                            setSrNo((prevC) =>
                                                page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                                            );
                                            setPage(page - 1);
                                        }}
                                    >
                                        {"Prev"}
                                    </Pagination.Prev>

                                    <Pagination.Item active>{page}</Pagination.Item>

                                    <Pagination.Next
                                        disabled={
                                            page === dataList.maxPage ||
                                                dataList.maxPage === 0 ||
                                                entriesPerPage > dataList.data.length
                                                ? true
                                                : false
                                        }
                                        onClick={() => {

                                            fetchleadList(
                                                page + 1,
                                                entriesPerPage,
                                                parseInt(srNo) + parseInt(entriesPerPage)
                                            );
                                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                                            setPage(page + 1);
                                        }}
                                    >
                                        {"Next"}
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    )
}

export default Leads