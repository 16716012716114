import React, { useEffect, useState } from "react";
import {
  get_common_attributes_api,
  create_common_attributes_api,
  update_common_attributes_api,
  delete_common_attributes_api,
  get_hardness_api,
  get_workpiece_material_api,
  get_work_piece_classi_api,
  get_toolmaterial_api,
} from "../../Common/API/index";
import { Menu, Plus, Pencil, Trash2 } from "lucide-react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from "react-bootstrap/Alert";
import { Typeahead } from "react-bootstrap-typeahead";

function CommonAttribute() {
  const [visible, setVisible] = useState(false);
  const [isLOV, setIsLOV] = useState(false);
  const [isGetFromMaster, setisGetFromMaster] = useState(false);
  const [masters_data, setMaster_data] = useState({ data: [], loading: false });
  const [action, setAction] = useState({ state: "create", data: {} });
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
    maxPage: 0,
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);

  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const attribute_type_data = [
    { name: "String", value: "string" },
    { name: "Integer", value: "integer" },
    { name: "List Of Value", value: "LOV" },
  ];
  const master_data = [
    { name: "Hardness" },
    { name: "Work Piece Material Classification" },
    { name: "Tool Material" },
  ];
  const schema = yup
    .object({
      attribute_name: yup
        .string()
        .max(200, "Maximum 200 characters allowed")
        .required("Enter attribute name"),
      attribute_type: yup
        .array()
        .min(1, "Select attribute type")
        .required("Select attribute type"),
      visible_in_RFQ: yup
        .array()
        .min(1, "Select visible in RFQ")
        .required("Select visible in RFQ"),
      visible_in_quote: yup
        .array()
        .min(1, "Select visible in quote")
        .required("Select visible in quote"),
      manufactured_products: yup
        .array()
        .min(1, "Select visible in manufactured products")
        .required("Select visible in manufactured products"),
      enter_values_by: yup
        .array()
        .when(["attribute_type"], ([attribute_type], schema) => {
          //   console.log("attribute_type",attribute_type)
          if (
            attribute_type !== undefined &&
            attribute_type.length > 0 &&
            attribute_type[0].name == "List Of Value"
          ) {
            return schema
              .min(1, "Enter values by is requried !")
              .required("Enter values by is requried !");
          } else {
            return schema;
          }
        }),
      attribute_value: yup
        .string()
        .when(["attribute_type"], ([attribute_type], schema) => {
          //   console.log("attribute_type",attribute_type)
          if (
            attribute_type !== undefined &&
            attribute_type.length > 0 &&
            (attribute_type[0].name == "String" ||
              attribute_type[0].name == "Integer")
          ) {
            return schema.required("Attribute value requried !");
          } else {
            return schema;
          }
        }),
      master_name: yup
        .array()
        .when(["enter_values_by"], ([enter_values_by], schema) => {
          //   console.log("enter_values_by",enter_values_by)
          if (
            enter_values_by !== undefined &&
            enter_values_by.length > 0 &&
            enter_values_by[0].name == "Get From Master"
          ) {
            return schema
              .min(1, "Master name is requried !")
              .required("Master name is requried !");
          } else {
            return schema;
          }
        }),
      attribute_values: yup
        .array()
        .when(["enter_values_by"], ([enter_values_by], schema) => {
          //   console.log("enter_values_by",enter_values_by)
          if (
            enter_values_by !== undefined &&
            enter_values_by.length > 0 &&
            enter_values_by[0].name == "Enter Manually"
          ) {
            return schema
              .min(1, "Attribute values are requried !")
              .required("Attribute values are requried !");
          } else {
            return schema;
          }
        }),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {},
  });

  const fetch_master_data = async (key) => {
    try {
      setMaster_data({ data: [], loading: true });

      let response;

      switch (key) {
        case "Hardness":
          response = await get_hardness_api();
          if (response?.data?.data && response.data.data.length > 0) {
            // console.log("response.data.data",response.data.data)
            let arr = response.data.data.map((item) => ({
              name: item.hardness_name,
              value: item.hardness_name,
            }));
            console.log("arr", arr);
            setMaster_data({ data: arr, loading: false });
          } else {
            setMaster_data({ data: [], loading: false });
          }
          break;
        case "Work Piece Material Classification":
          response = await get_work_piece_classi_api();
          if (response?.data?.data && response.data.data.length > 0) {
            console.log("response.data.data", response.data.data);
            let arr = response.data.data.map((item) => ({
              name: item.description,
              value: item.description,
              iso_classification: item.iso_classification,
            }));
            console.log("arr2", arr);
            setMaster_data({ data: arr, loading: false });
          } else {
            setMaster_data({ data: [], loading: false });
          }
          break;
        case "Tool Material":
          response = await get_toolmaterial_api();
          if (response?.data?.data && response.data.data.length > 0) {
            console.log("response.data.data", response.data.data);
            let arr = response.data.data.map((item) => ({
              name: item.tool_material,
              value: item.tool_material,
            }));
            console.log("arr2", arr);
            setMaster_data({ data: arr, loading: false });
          } else {
            setMaster_data({ data: [], loading: false });
          }
          break;
        default:
          console.error("Invalid API key");
          setMaster_data({ data: [], loading: false });
          return;
      }
    } catch (error) {
      setMaster_data({ data: [], loading: false });

      console.error("Error fetching data:", error);
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (action.state == "create") {
      let payload = {
        attribute_name: data.attribute_name,
        attribute_type: data.attribute_type[0].value,
        visible_in_RFQ: data.visible_in_RFQ[0] == "Yes" ? true : false,
        visible_in_quote: data.visible_in_quote[0] == "Yes" ? true : false,
        manufactured_products:
          data.manufactured_products[0] == "Yes" ? true : false,
        attribute_value: null,
      };
      if (
        payload.attribute_type == "string" ||
        payload.attribute_type == "integer"
      ) {
        console.log("data.attribute_value", data.attribute_value);
        payload.attribute_value = [data.attribute_value];
      }

      if (payload.attribute_type == "LOV" && masters_data.data.length > 0) {
        payload.attribute_value = masters_data.data;
      }
      if (payload.attribute_type == "LOV" && masters_data.data.length == 0) {
        let arr = data.attribute_values.map((item) => ({
          name: item.label,
          value: item.label,
        }));
        payload.attribute_value = arr;
      }

      if (data.uom_values && data.uom_values.length > 0) {
        let arr = data.uom_values.map((item) => ({
          name: item.label,
          value: item.label,
        }));
        payload.uom_values = arr;
      }

      console.log("payload", payload);

      create_common_attributes_api(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Common attribute created successfully");
          setVisible(false);
          reset();
          fetchCommonAttributeList(1, entriesPerPage);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message);
        }
      );
    } else {
      const payload_update = {
        uuid: action.data.uuid,
        attribute_name: data.attribute_name,
        attribute_type: data.attribute_type[0].value,
        visible_in_RFQ: data.visible_in_RFQ[0] == "Yes" ? true : false,
        visible_in_quote: data.visible_in_quote[0] == "Yes" ? true : false,
        manufactured_products:
          data.manufactured_products[0] == "Yes" ? true : false,
        attribute_value: null,
      };

      if (
        payload_update.attribute_type == "string" ||
        payload_update.attribute_type == "integer"
      ) {
        console.log("data.attribute_value", data.attribute_value);
        payload_update.attribute_value = [data.attribute_value];
      }

      if (
        payload_update.attribute_type == "LOV" &&
        masters_data.data.length > 0
      ) {
        payload_update.attribute_value = masters_data.data;
      }
      if (
        payload_update.attribute_type == "LOV" &&
        masters_data.data.length == 0
      ) {
        let arr = data.attribute_values.map((item) => ({
          name: item.label,
          value: item.label,
        }));
        payload_update.attribute_value = arr;
      }

      if (data.uom_values && data.uom_values.length > 0) {
        let arr = data.uom_values.map((item) => ({
          name: item.label,
          value: item.label,
        }));
        payload_update.uom_values = arr;
      }

      console.log("payload_update", payload_update);

      update_common_attributes_api(payload_update).then(
        (res) => {
          console.log("res", res);
          toast.success("Common attribute updated successfully !");
          setVisible(false);
          reset();
          fetchCommonAttributeList(1, entriesPerPage);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message);
        }
      );
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchCommonAttributeList(1, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, []);

  function fetchCommonAttributeList(pg, epp) {
    setDataList({ ...dataList, loading: true });

    const payload = {
      limit: epp,
      page: pg,
    };

    get_common_attributes_api(payload).then(
      (res) => {
        console.log("res", res);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
          maxPage: calMaxPage(res.data.total),
        });
      },
      (err) => {
        console.log("err", err);
        toast.error(err.response.data.message);
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const delCommonAttributes = (val) => {
    Swal.fire({
      title: "Are you sure to delete",
      text: val.attribute_name,
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        delete_common_attributes_api({ common_attribute_id: val.uuid }).then(
          (res) => {
            toast.success(`${val.attribute_name} deleted successfully`);

            if (dataList.data.length == 1 && page > 1) {
              fetchCommonAttributeList(page - 1, entriesPerPage);

              setPage(page - 1);
              setSrNo((prevC) =>
                page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
              );
            } else {
              fetchCommonAttributeList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message);
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size={"lg"}
        backdrop="static"
      >
        <ModalHeader className="modalHeader" closeButton>
          <Modal.Title>
            {" "}
            {`${
              action.state == "create" ? "Create" : "Update"
            } Common Attribute `}
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="attribute_name">
                  Common Attribute Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="attribute_name"
                  className={`form-control ${
                    errors.attribute_name && "is-invalid"
                  }`}
                  placeholder="Enter attribute Name"
                  {...register("attribute_name")}
                />

                {errors.attribute_name && (
                  <span className="text-danger">
                    {errors.attribute_name?.message}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="attribute_type">
                  Attribute Type
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`attribute_type`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="name"
                      multiple={false}
                      options={attribute_type_data}
                      placeholder="Select attribute type"
                      onChange={(selected) => {
                        onChange(selected);

                        if (
                          selected &&
                          selected.length > 0 &&
                          selected[0].name == "List Of Value"
                        ) {
                          setIsLOV(true);
                        } else {
                          setIsLOV(false);
                          setMaster_data({ data: [], loading: false });
                          setisGetFromMaster(false);
                          setValue("master_name", []);
                          setValue("enter_values_by", []);
                        }
                      }}
                      onBlur={onBlur}
                      selected={value}
                      disabled={action.state == "update" ? true : false}
                      isInvalid={errors.attribute_type ? true : false}
                    />
                  )}
                />

                {errors.attribute_type && (
                  <span className="text-danger">
                    {errors.attribute_type?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="visible_in_RFQ">
                  Visible In RFQ
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`visible_in_RFQ`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="visible_in_RFQ"
                      multiple={false}
                      options={["Yes", "No"]}
                      placeholder="Select visible in RFQ"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.visible_in_RFQ ? true : false}
                    />
                  )}
                />

                {errors.visible_in_RFQ && (
                  <span className="text-danger">
                    {errors.visible_in_RFQ?.message}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="visible_in_quote">
                  Visible in Quote
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`visible_in_quote`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="visible_in_quote"
                      multiple={false}
                      options={["Yes", "No"]}
                      placeholder="Select visible in quote"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.visible_in_quote ? true : false}
                    />
                  )}
                />

                {errors.visible_in_quote && (
                  <span className="text-danger">
                    {errors.visible_in_quote?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="manufactured_products">
                  Visible in Manufactured Products
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name={`manufactured_products`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="manufactured_products"
                      multiple={false}
                      options={["Yes", "No"]}
                      placeholder="Select visible in manufactured products"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.manufactured_products ? true : false}
                    />
                  )}
                />

                {errors.manufactured_products && (
                  <span className="text-danger">
                    {errors.manufactured_products?.message}
                  </span>
                )}
              </div>
              {!isLOV && (
                <div className="col-12 col-md-6 mb-3">
                  <label className="form-label" htmlFor="attribute_value">
                    Attribute Value
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="attribute_value"
                    className={`form-control ${
                      errors.attribute_value && "is-invalid"
                    }`}
                    placeholder="Enter attribute value"
                    {...register("attribute_value")}
                  />
                  {errors.attribute_value && (
                    <span className="text-danger">
                      {errors.attribute_value?.message}
                    </span>
                  )}
                </div>
              )}
              {isLOV && (
                <div className="col-12 col-md-6 mb-3">
                  <label className="form-label" htmlFor="enter_values_by">
                    Enter Attribute Values By
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={`enter_values_by`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={[
                          { name: "Get From Master" },
                          { name: "Enter Manually" },
                        ]}
                        placeholder="Select enter values by"
                        onChange={(selected) => {
                          console.log("selected", selected);
                          onChange(selected);
                          if (
                            selected &&
                            selected.length > 0 &&
                            selected[0].name == "Get From Master"
                          ) {
                            setisGetFromMaster(true);
                          } else {
                            setisGetFromMaster(false);
                            setMaster_data({ data: [], loading: false });
                            setValue("master_name", []);
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.enter_values_by ? true : false}
                      />
                    )}
                  />

                  {errors.enter_values_by && (
                    <span className="text-danger">
                      {errors.enter_values_by?.message}
                    </span>
                  )}
                </div>
              )}
              {isLOV && isGetFromMaster && (
                <div className="col-12 col-md-6 mb-3">
                  <label className="form-label" htmlFor="master_name">
                    Get From Master
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={`master_name`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={master_data}
                        placeholder="Select master"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected.length > 0) {
                            fetch_master_data(selected[0].name);
                          } else {
                            setMaster_data({ data: [], loading: false });
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.master_name ? true : false}
                      />
                    )}
                  />

                  {errors.master_name && (
                    <span className="text-danger">
                      {errors.master_name?.message}
                    </span>
                  )}
                </div>
              )}
              {isLOV && !isGetFromMaster && (
                <div className="col-12 col-md-6 mb-3">
                  <label className="form-label" htmlFor="attribute_values">
                    Attribute Values
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={`attribute_values`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-example"
                        multiple
                        newSelectionPrefix="Add a new item: "
                        options={[]}
                        placeholder="Enter attribute values..."
                        onChange={(selected) => {
                          onChange(selected);
                          console.log("selected", selected);
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.attribute_values ? true : false}
                      />
                    )}
                  />

                  {errors.attribute_values && (
                    <span className="text-danger">
                      {errors.attribute_values?.message}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="uom_values">
                  UOM Values
                </label>
                <Controller
                  name={`uom_values`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      allowNew
                      id="custom-selections-example"
                      multiple
                      newSelectionPrefix="Add a new item: "
                      options={[]}
                      placeholder="Enter uom values..."
                      onChange={(selected) => {
                        onChange(selected);
                        console.log("selected", selected);
                      }}
                      onBlur={onBlur}
                      selected={value}
                      // isInvalid={errors.attribute_values ? true : false}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-yellow px-3"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>

      <div className="attribute_name">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading mb-0 fw600">Common Attribute</h1>
          <div>
            <span
              className="cursor_pointer px-3 me-0 me-md-2"
              onClick={() => {
                // openNav();
              }}
            >
              <Menu size={18} className="me-1 p-0" />

              {"Filter Menu"}
            </span>
            <button
              onClick={() => {
                setVisible(true);
                setAction({ ...action, state: "create", data: {} });
                reset();
              }}
              className="btn btn-blue"
            >
              <Plus size={18} className="me-1 p-0" />
              Create Common Attribute
            </button>
          </div>
        </div>

        <div className="card mt-3 border-0">
          <div className="card-body">
            <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
              <p className="mb-0">
                Show
                <select
                  className="mx-1"
                  defaultValue={"10"}
                  onChange={(e) => {
                    setEntriesPerPage(e.target.value);
                    fetchCommonAttributeList(page, e.target.value);
                  }}
                >
                  <option value={"10"}>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                Entries
              </p>
            </div>

            {dataList.loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : dataList.data.length == 0 ? (
              <Alert className="text-center" variant={"danger"}>
                NO Common Attributes Found
              </Alert>
            ) : (
              <div className="table-responsive">
                <table className="table mt-2 table-striped">
                  <thead className="table-grey">
                    <tr className="">
                      <th scope="col">{"SrNo"}</th>
                      <th scope="col">{"Attribute Name"}</th>
                      <th scope="col">{"Attribute Type"}</th>

                      <th scope="col">{"Visible IN RFQ"}</th>
                      <th scope="col">{"Visible IN Quote"}</th>
                      <th scope="col">{"Manufactured Products"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.data.map((val, i) => (
                      <tr key={val.uuid}>
                        <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                        <td>{val.attribute_name}</td>
                        <td>
                          {val.attribute_type == null
                            ? "NA"
                            : val.attribute_type}
                        </td>
                        <td>{val.visible_in_RFQ ? "YES" : "NO"}</td>
                        <td>{val.visible_in_quote ? "YES" : "NO"}</td>
                        <td>{val.manufactured_products ? "YES" : "NO"}</td>
                        <td>
                          <>
                            <Tooltip id={"edit-tooltip" + i} place="top" />
                            <div
                              data-tooltip-id={"edit-tooltip" + i}
                              data-tooltip-content={"Edit Common Attribute"}
                              className="editIcon m-1 cursor_pointer"
                              onClick={() => {
                                setVisible(true);
                                reset();
                                setAction({
                                  ...action,
                                  state: "update",
                                  data: val,
                                });
                                console.log("val", val);
                                setValue("attribute_name", val.attribute_name);
                                if (val.attribute_type == "integer") {
                                  setValue("attribute_type", [
                                    {
                                      name: "Integer",
                                      value: val.attribute_type,
                                    },
                                  ]);
                                  setisGetFromMaster(false);
                                  setIsLOV(false);
                                  // console.log("val.attribute_value",val.attribute_value[0])
                                  setValue(
                                    "attribute_value",
                                    val.attribute_value[0]
                                  );
                                } else if (val.attribute_type == "string") {
                                  setValue("attribute_type", [
                                    {
                                      name: "String",
                                      value: val.attribute_type,
                                    },
                                  ]);
                                  setIsLOV(false);
                                  setisGetFromMaster(false);
                                  setValue(
                                    "attribute_value",
                                    val.attribute_value[0]
                                  );
                                } else {
                                  setValue("attribute_type", [
                                    {
                                      name: "List Of Value",
                                      value: val.attribute_type,
                                    },
                                  ]);
                                  setIsLOV(true);

                                  if (
                                    val.attribute_value &&
                                    val.attribute_value.length < 10
                                  ) {
                                    let arr2 = val.attribute_value.map(
                                      (item) => ({name:item.name,label:item.name})
                                    );
                                    console.log("arr", arr2);
                                    setValue("attribute_values", arr2);
                                    setisGetFromMaster(false);
                                    setValue("enter_values_by", [
                                      { name: "Enter Manually" },
                                    ]);
                                  }
                                  if (
                                    val.attribute_value &&
                                    val.attribute_value.length > 10
                                  ) {
                                    // let arr2=val.attribute_value.map((item)=>item.name);
                                    // console.log("arr",arr2)
                                    // setValue("attribute_values",arr2);
                                    setisGetFromMaster(true);
                                    setValue("enter_values_by", [
                                      { name: "Get From Master" },
                                    ]);
                                  }

                                  // setValue("attribute_values",[...val.attribute_value])
                                }
                                setValue(
                                  "visible_in_RFQ",
                                  val.visible_in_RFQ ? ["Yes"] : ["No"]
                                );
                                setValue(
                                  "visible_in_quote",
                                  val.visible_in_quote ? ["Yes"] : ["No"]
                                );
                                setValue(
                                  "manufactured_products",
                                  val.manufactured_products ? ["Yes"] : ["No"]
                                );
                                let arr =
                                  val.uom_values == null
                                    ? []
                                    : val.uom_values.map((item) => item.name);

                                setValue("uom_values", arr);
                              }}
                            >
                              <Pencil size={18} color="#fff" />
                            </div>
                          </>
                          <>
                            <Tooltip id={"del-tooltip" + i} place="top" />
                            <div
                              data-tooltip-id={"del-tooltip" + i}
                              data-tooltip-content={"Delete Common Attribute"}
                              className="deleteIcon m-1 cursor_pointer"
                              onClick={() => {
                                delCommonAttributes(val);
                              }}
                            >
                              <Trash2 size={18} color="#fff" />
                            </div>
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {dataList.loading ? null : dataList.data?.length > 0 ? (
              <div className="mt-4 d-flex justify-content-between">
                <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${
                  dataList.data.length + entriesPerPage * page - entriesPerPage
                } of ${dataList.totalRecords} entries`}</p>
                <Pagination>
                  <Pagination.Prev
                    disabled={page === 1 ? true : false}
                    onClick={() => {
                      fetchCommonAttributeList(
                        page - 1,
                        entriesPerPage,
                        parseInt(srNo) - parseInt(entriesPerPage)
                      );
                      setSrNo((prevC) =>
                        page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                      );
                      setPage(page - 1);
                    }}
                  >
                    {"Prev"}
                  </Pagination.Prev>

                  <Pagination.Item active>{page}</Pagination.Item>

                  <Pagination.Next
                    disabled={
                      page === dataList.maxPage ||
                      dataList.maxPage === 0 ||
                      entriesPerPage > dataList.data.length
                        ? true
                        : false
                    }
                    onClick={() => {
                      fetchCommonAttributeList(
                        page + 1,
                        entriesPerPage,
                        parseInt(srNo) + parseInt(entriesPerPage)
                      );
                      setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                      setPage(page + 1);
                    }}
                  >
                    {"Next"}
                  </Pagination.Next>
                </Pagination>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CommonAttribute;
