import React from 'react';
import NavbarComp from './NavbarComp';
import Footer from './Footer';
import AppContent from './AppContent';

function DefaultLayout() {
    return (
        <div id="root_div">
            <NavbarComp />
            <div id="root_body" >
                <AppContent />
            </div>
            <Footer />
        </div>
    )
}

export default DefaultLayout