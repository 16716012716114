import React, { useEffect, useState } from "react";
import {
  get_product_api,
  get_tool_cat_api,
  get_tool_sub_cat_api,
  get_tooltype_api,
} from "../../Common/API/index";
import { useNavigate } from "react-router-dom";
import { Menu, Plus, Pencil, Trash2 } from "lucide-react";
import { Pagination } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Typeahead } from "react-bootstrap-typeahead";
import Swal from "sweetalert2";
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
const schema = yup
  .object({
    tool_type: yup
      .array()
      .min(1, "Select tool type")
      .required("Select tool type"),
    tool_category: yup
      .array()
      .min(1, "Select tool category")
      .required("Select tool category"),
    tool_subcategory: yup
      .array()
      .min(1, "Select tool sub category")
      .required("Select tool sub category"),
    tool_subcategory_code: yup
      .string()
      .max(200, "Maximum 200 characters allowed")
      .required("Enter sub category code"),
    description: yup
      .string()
      .max(500, "Maximum 500 characters allowed")
      .notRequired("Enter description"),
    series: yup
      .string()
      .max(200, "Maximum 200 characters allowed")
      .required("Enter series"),
  })
  .required();

function Item() {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState({ state: "create", data: {} });
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
    maxPage: 0,
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [productList, setProductList] = useState({ data: [], loading: true });
  const [toolTypeList, setToolTypeList] = useState({ data: [], loading: true });
  const [toolCatList, setToolCatList] = useState({ data: [], loading: true });
  const [toolSubCatList, setToolSubCatList] = useState({
    data: [],
    loading: true,
  });
  const [filterCatList,setfilterCatList]=useState({
    data:[],loading:false
  })
  const [filterSubCatList,setfilterSubCatList]=useState({
    data:[],loading:false
  })
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [filterConfig, setFilterConfig] = useState({
    searchVal: "",
    tool_type: [],
    tool_category: [],
    tool_subcategory: [],
  });

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      searchVal: "",
      tool_type: [],
      tool_category: [],
      tool_subcategory: [],
    });
    setSrNo(0);
    setPage(1);
  }

  useEffect(() => {
    fetchProducts();

    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchUserTypeList(1, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filterConfig]);

  function fetchProducts() {
    get_product_api().then(
      (res) => {
        //console.log("res", res);

        setProductList({ ...productList, loading: false, data: res.data.data });
      },
      (err) => {
        console.log("err", err);
        setProductList({ ...productList, loading: false, data: [] });
      }
    );
  }

  function fetchUserTypeList(pg, epp) {
    setDataList({ ...dataList, loading: true });

    const payload = {
      limit: epp,
      page: pg,
    };

    if (filterConfig.searchVal !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.search = filterConfig.searchVal.trim();
    } else {
      delete payload.search;
    }

    if (filterConfig.tool_type.length > 0) {
      payload.tool_type = filterConfig.tool_type?.[0]?.uuid;
    } else {
      delete payload.tool_type;
    }

    if (filterConfig.tool_category.length > 0) {
      payload.tool_category = filterConfig.tool_category?.[0]?.uuid;
    } else {
      delete payload.tool_category;
    }

    if (filterConfig.tool_subcategory.length > 0) {
      payload.tool_subcategory = filterConfig.tool_subcategory?.[0]?.uuid;
    } else {
      delete payload.tool_subcategory;
    }


    get_product_api(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
          maxPage: calMaxPage(res.data.total),
        });
      },
      (err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  function fetchToolTypes() {
    get_tooltype_api().then(
      (res) => {
        setToolTypeList({
          ...toolTypeList,
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        console.log("err", err);
        setToolTypeList({ ...toolTypeList, loading: false, data: [] });
      }
    );
  }

  function fetchToolCats() {
    get_tool_cat_api().then(
      (res) => {
        setToolCatList({ ...toolCatList, loading: false, data: res.data.data });
      },
      (err) => {
        setToolCatList({ ...toolCatList, loading: false, data: [] });
      }
    );
  }

  function fetchToolSubCats() {
    get_tool_sub_cat_api().then(
      (res) => {
        //console.log("res", res);

        setToolSubCatList({
          ...toolSubCatList,
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        console.log("err", err);
        setToolCatList({ ...toolSubCatList, loading: false, data: [] });
      }
    );
  }

  const addProduct = () => {
    navigate("/app/product");
  };

  const editProduct = (uuid) => {
    navigate(`/app/product/${uuid}`);
  };

  const filter_lists=(id,type,id2)=>{

    if(type=="ToolCategory"){
      setfilterCatList({data:[], loading:true})

      let arr=toolCatList.data.filter((item)=>item.tool_type==id);
      setfilterCatList({data:arr, loading:false})
     }else{
      setfilterSubCatList({data:[],loading:true});
      let arr2=toolSubCatList.data.filter((item)=>item.tool_type==id && item.tool_category==id2); 
      setfilterSubCatList({data:arr2, loading:false})
     }
  }

  function openNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
    fetchToolTypes();
     fetchToolCats();
     fetchToolSubCats();
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="min-vh-100 " id="root_div_main">
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Search"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by product code"
                value={filterConfig.searchVal}
                onChange={(val) => {
                  const inputValue = val.target.value; // Trim leading and trailing whitespaces
                  //  console.log("inputValue",inputValue)
                  if (!inputValue.trim()) {
                    // console.log("inputValue2",inputValue)
                    if (filterConfig.searchVal.length == 1) {
                      // console.log("inputValue3",inputValue)
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }
                  } else {
                    // console.log("inputValue4",inputValue)
                    setFilterConfig({
                      ...filterConfig,
                      searchVal: inputValue,
                    });
                  }
                }}
              />
            </div>
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Tool Type"}
              </label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="tool_type_name"
                multiple={false}
                // size="sm"
                options={toolTypeList.data}
                isLoading={toolTypeList.loading}
                selected={filterConfig.tool_type}
                onChange={(val) => {
                  // console.log("product val", val);
                  if (val.length > 0) {
                    // console.log(val);
                    setFilterConfig({ ...filterConfig, tool_type: val });
                  filter_lists(val[0].uuid,"ToolCategory");
                  } else {
                    setfilterCatList({data:[],loading:false});
                    setfilterSubCatList({data:[],loading:false});
                    
                    setFilterConfig({ ...filterConfig, tool_type: [], tool_category:[],tool_subcategory:[] });
                  }
                }}
                placeholder="Select tool type"
              />
            </div>
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Tool Category"}
              </label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="description"
                multiple={false}
                // size="sm"
                options={filterCatList.data}
                isLoading={filterCatList.loading}
                selected={filterConfig.tool_category}
                onChange={(val) => {
                  // console.log("product val", val);
                  if (val.length > 0) {
                    // console.log(val);
                    setFilterConfig({ ...filterConfig, tool_category: val });
                    filter_lists(val[0].tool_type,"ToolSubCategory",val[0].uuid);

                  } else {
                    setfilterSubCatList({data:[],loading:false})
                    setFilterConfig({ ...filterConfig, tool_category: [] ,tool_subcategory:[]});
                  }
                }}
                placeholder="Select tool category"
              />
            </div>
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Tool Sub-Category"}
              </label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="description"
                multiple={false}
                // size="sm"
                options={filterSubCatList.data}
                isLoading={filterSubCatList.loading}
                selected={filterConfig.tool_subcategory}
                onChange={(val) => {
                  // console.log("product val", val);
                  if (val.length > 0) {
                    // console.log(val);
                    setFilterConfig({ ...filterConfig, tool_subcategory: val });
                  } else {
                    setFilterConfig({ ...filterConfig, tool_subcategory: [] });
                  }
                }}
                placeholder="Select tool sub category"
              />
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {"Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="tool_type">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="page-heading mb-0 fw600">Item</h1>
            <div>
              <span
                className="cursor_pointer px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <Menu size={18} className="me-1 p-0" />

                {"Filter Menu"}
              </span>
              <button
                onClick={() => {
                  addProduct();
                }}
                className="btn btn-blue"
              >
                <Plus size={18} className="me-1 p-0" /> Create Product
              </button>
            </div>
          </div>

          <div className="card mt-3 border-0">
            <div className="card-body">
              <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
                <p className="mb-0">
                  Show
                  <select
                    className="mx-1"
                    defaultValue={"10"}
                    onChange={(e) => {
                      setEntriesPerPage(e.target.value);
                      fetchUserTypeList(page, e.target.value);
                    }}
                  >
                    <option value={"10"}>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Entries
                </p>
              </div>

              {dataList.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : dataList.data.length == 0 ? (
                <Alert className="text-center" variant={"danger"}>
                  No Item Found
                </Alert>
              ) : (
                <div className="table-responsive">
                  <table className="table mt-2 table-striped">
                    <thead className="table-grey">
                      <tr className="">
                        <th scope="col">{"SrNo"}</th>

                        <th scope="col">{"Tool Type"}</th>
                        <th scope="col">{"Tool Category"}</th>
                        <th scope="col">{"Tool Sub Category"}</th>
                        <th scope="col">{"Product Code"}</th>

                        <th scope="col">{"Action"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.data.map((val, i) => (
                        <tr key={val.uuid}>
                          <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                          <td>
                            {val.tooltypedata.tool_type_name == null
                              ? "NA"
                              : val.tooltypedata.tool_type_name}
                          </td>
                          <td>
                            {val.toolcategory.description == null
                              ? "NA"
                              : val.toolcategory.description}
                          </td>
                          <td>
                            {val.toolsubcategory.description == null
                              ? "NA"
                              : val.toolsubcategory.description}
                          </td>
                          <td>
                            {val.product_code == null ? "NA" : val.product_code}
                          </td>
                          <td>
                            <>
                              <Tooltip id={"edit-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content={"Edit Item"}
                                className="editIcon m-1 cursor_pointer"
                                onClick={() => editProduct(val.uuid)}
                              >
                                <Pencil size={18} color="#fff" />
                              </div>
                            </>
                            <>
                              <Tooltip id={"del-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"del-tooltip" + i}
                                data-tooltip-content={"Delete Item"}
                                className="deleteIcon m-1 cursor_pointer"
                                onClick={() => {
                                  console.log("clicked");
                                  // delMachinCat(val)
                                }}
                              >
                                <Trash2 size={18} color="#fff" />
                              </div>
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {dataList.loading ? null : dataList.data?.length > 0 ? (
                <div className="mt-4 d-flex justify-content-between">
                  <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${
                    dataList.data.length +
                    entriesPerPage * page -
                    entriesPerPage
                  } of ${dataList.totalRecords} entries`}</p>
                  <Pagination>
                    <Pagination.Prev
                      disabled={page === 1 ? true : false}
                      onClick={() => {
                        fetchUserTypeList(
                          page - 1,
                          entriesPerPage,
                          parseInt(srNo) - parseInt(entriesPerPage)
                        );
                        setSrNo((prevC) =>
                          page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                        );
                        setPage(page - 1);
                      }}
                    >
                      {"Prev"}
                    </Pagination.Prev>

                    <Pagination.Item active>{page}</Pagination.Item>

                    <Pagination.Next
                      disabled={
                        page === dataList.maxPage ||
                        dataList.maxPage === 0 ||
                        entriesPerPage > dataList.data.length
                          ? true
                          : false
                      }
                      onClick={() => {
                        fetchUserTypeList(
                          page + 1,
                          entriesPerPage,
                          parseInt(srNo) + parseInt(entriesPerPage)
                        );
                        setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                        setPage(page + 1);
                      }}
                    >
                      {"Next"}
                    </Pagination.Next>
                  </Pagination>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Item;
