import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Settings, LogOut } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

function NavbarComp() {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    // console.log(location);
  }, []);

  function logoutuser() {
    localStorage.clear();
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }

  return (
    <React.Fragment>
      <Navbar>
        <Container fluid style={{ padding: "0px 22px" }}>
          {/* <Navbar.Brand href="#home">Navbar</Navbar.Brand> */}
          <Nav className="me-auto">
            <NavDropdown
              active={["/app/machine_category", "/app/machine_list"].includes(
                location.pathname
              )}
              title="Machine Management"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                active={location.pathname === "/app/machine_category"}
                href="/app/machine_category"
              >
                Machine Category
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/machine_list"}
                href="/app/machine_list"
              >
                Machine
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/machine_dim_parameter_list"}
                href="/app/machine_dim_parameter_list"
              >
                Machine Dim Parameter
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              active={[
                "/app/tooltype_list",
                "/app/tool_material_list",
                "/app/tool_category_list",
                "/app/tool_subcategory_list",
              ].includes(location.pathname)}
              title="Tool Management"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                active={location.pathname === "/app/tooltype_list"}
                href="/app/tooltype_list"
              >
                Tool Type
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/tool_material_list"}
                href="/app/tool_material_list"
              >
                Tool Material
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/tool_category_list"}
                href="/app/tool_category_list"
              >
                Tool Category
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/tool_subcategory_list"}
                href="/app/tool_subcategory_list"
              >
                Tool Sub Category
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/tool_type_mapping"}
                href="/app/tool_type_mapping"
              >
                Tool Type Mapping
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              active={location.pathname === "/app/uom_list"}
              href="/app/uom_list"
            >
              UOM
            </Nav.Link>

            <Nav.Link
              active={location.pathname === "/app/lead_list"}
              href="/app/lead_list"
            >
             Leads
            </Nav.Link>


            <NavDropdown
              active={[
                "/app/usertype_list",
                "/app/user_list",
              ].includes(location.pathname)}
              title="User Management"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                active={location.pathname === "/app/usertype_list"}
                href="/app/usertype_list"
              >
                  User Type
              </NavDropdown.Item>
              <NavDropdown.Item
                 active={location.pathname === "/app/user_list"}
                 href="/app/user_list"
               >
                 Users
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link
              active={location.pathname === "/app/usertype_list"}
              href="/app/usertype_list"
            >
              User Type
            </Nav.Link>

            <Nav.Link
              active={location.pathname === "/app/user_list"}
              href="/app/user_list"
            >
              User Management
            </Nav.Link> */}
            <Nav.Link
              active={location.pathname === "/app/hardness"}
              href="/app/hardness"
            >
              Hardness
            </Nav.Link>

            <NavDropdown
              active={[
                "/app/tooling_parameter_list",
                "/app/common_attribute",
              ].includes(location.pathname)}
              title="Parameter"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                active={location.pathname === "/app/tooling_parameter_list"}
                href="/app/tooling_parameter_list"
              >
                Tooling Parameter
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/common_attribute"}
                href="/app/common_attribute"
              >
                Common Attribute
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              active={[
                "/app/work_piece_material_classification_list",
                "/app/work_piece_material_list",
              ].includes(location.pathname)}
              title="Work Piece"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                active={
                  location.pathname ===
                  "/app/work_piece_material_classification_list"
                }
                href="/app/work_piece_material_classification_list"
              >
                Work Piece Material Class
              </NavDropdown.Item>
              <NavDropdown.Item
                active={location.pathname === "/app/work_piece_material_list"}
                href="/app/work_piece_material_list"
              >
                Work Piece Material
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              active={location.pathname === "/app/products"}
              href="/app/products"
            >
              Products
            </Nav.Link>

            {/* <Nav.Link active={location.pathname === "/app/work_piece_material_classification_list"}
              href="/app/work_piece_material_classification_list">Work Piece Material Class</Nav.Link>
            <Nav.Link active={location.pathname === "/app/work_piece_material_list"}
              href="/app/work_piece_material_list">Work Piece Material</Nav.Link> */}
          </Nav>

          <Navbar.Collapse id="navbar-light-example">
            <Nav className="ms-auto">
              <NavDropdown
                id="custom-dropdown"
                title={
                  <span style={{ color: "black" }}>
                    Welcome Admin <Settings size={18} />
                  </span>
                }
                menuVariant="light"
                align={"start"}
              >
                <NavDropdown.Item onClick={() => logoutuser()}>
                  <LogOut size={18} /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}

export default NavbarComp;
