import React, { useEffect, useState } from 'react'
import {
    get_hardness_api, create_hardness_api,
    update_hardness_api, delete_hardness_api
} from "../../Common/API/index";
import { Menu, Plus, Pencil, Trash2 } from 'lucide-react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Pagination } from 'react-bootstrap';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from "react-tooltip";
import Swal from 'sweetalert2'
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faBan,
    faFilter,
    faCog,
    faDownload,
  } from "@fortawesome/free-solid-svg-icons";
const schema = yup
    .object({
        hardness_name: yup.string().
            max(200, "Maximum 200 characters allowed")
            .required("Enter hardness name"),
        hardness_specification: yup.string().
            max(500, "Maximum 500 characters allowed")
            .notRequired("Enter hardness specification"),
    })
    .required()

function Hardness() {
    const [visible, setVisible] = useState(false);
    const [action, setAction] = useState({ state: "create", data: {} });
    const [dataList, setDataList] = useState({ data: [], loading: true, totalRecords: '', maxPage: 0 });
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);

    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [filterConfig, setFilterConfig] = useState({
        searchVal: "",
      });
    
      function clearFilter() {
        setFilterConfig({
          ...filterConfig,
          searchVal: "",
        });
        setSrNo(0);
        setPage(1);
     
      }
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            hardness_specification: null
        },
    })
    const onSubmit = (data) => {
        console.log(data);

        
        if (action.state == "create") {

            create_hardness_api(data).then((res) => {
                console.log("res", res);
                toast.success('Hardness created successfully');
                setVisible(false);
                reset();
                fetchHardnessList(1, entriesPerPage);
            }, (err) => {
                console.log("err", err);
                toast.error(err.response.data.message);
            });
        } else {

            const payload = {
                hardness_name: data.hardness_name,
                hardness_specification: data.hardness_specification,
                uuid: action.data.uuid
            };

            update_hardness_api(payload).then((res) => {
                console.log("res", res);
                toast.success('Hardness updated successfully');
                setVisible(false);
                reset();
                fetchHardnessList(1, entriesPerPage);
            }, (err) => {
                console.log("err", err);
                toast.error(err.response.data.message);
            });
        }
    }


    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setPage(1);
            fetchHardnessList(1, entriesPerPage);
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, []);



    function fetchHardnessList(pg, epp) {
        setDataList({ ...dataList, loading: true });

        const payload = {
            limit: epp,
            page: pg,
        };

        get_hardness_api(payload).then((res) => {
            console.log("res", res);
            setDataList({
                ...dataList, loading: false, data: res.data.data,
                totalRecords: res.data.total, maxPage: calMaxPage(res.data.total)
            });


        }, (err) => {
            console.log("err", err);
            toast.error(err.response.data.message);
            setDataList({ ...dataList, loading: false, data: [] });
        });
    }


    const delHardness = (val) => {
        Swal.fire({
            title: "Are you sure to delete",
            text: val.hardness_name,
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                delete_hardness_api({ hardness_id: val.uuid }).then((res) => {
                    toast.success(`${val.hardness_name} deleted successfully`);

                    if (dataList.data.length == 1 && page > 1) {
                        fetchHardnessList(page - 1, entriesPerPage);

                        setPage(page - 1);
                        setSrNo((prevC) => page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage));
                    } else {
                        fetchHardnessList(page, entriesPerPage);
                    }
                }, (err) => {
                    toast.error(err.response.data.message);
                });
            }
        });
    };

    function openNav(){
        const sidebar = document.getElementById("mySidebar");
        const mainDiv = document.getElementById("root_div_main");
    
        if (sidebar.style.width === "320px") {
          // Sidebar is open, so close it
          sidebar.style.width = "0";
          mainDiv.style.marginLeft = "0";
          sidebar.style.overflowX = "hidden";
        } else {
          // Sidebar is closed, so open it
          sidebar.style.width = "320px";
          mainDiv.style.marginLeft = "320px";
          setTimeout(() => {
            sidebar.style.overflowX = "visible";
          }, 200);
    }}

    function closeNav() {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("root_div_main").style.marginLeft = "0";
    
        document.getElementById("mySidebar").style.overflowX = "hidden";
      }


    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />



            <Modal show={visible} onHide={() => setVisible(false)} size={"lg"} backdrop="static">
                <ModalHeader className='modalHeader' closeButton>
                    <Modal.Title> {`${action.state == "create" ? "Create" : "Update"} Hardness `}</Modal.Title>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className='row'>
                            <div className='col-12 col-md-6 mb-3'>

                                <label
                                    className="form-label"
                                    htmlFor="hardness_name">Hardness Name
                                    <span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="hardness_name"
                                    className={`form-control ${errors.hardness_name && "is-invalid"}`}
                                    placeholder="Enter Hardness Name"
                                    {...register("hardness_name")}
                                />

                                {errors.hardness_name &&
                                    <span className='text-danger'>{errors.hardness_name?.message}</span>
                                }


                            </div>
                            <div className='col-12 col-md-6 mb-3'>

                                <label
                                    className="form-label"
                                    htmlFor="hardness_specification">Hardness Specification
                                </label>
                                <input
                                    type="text"
                                    id="hardness_specification"
                                    className={`form-control ${errors.hardness_specification && "is-invalid"}`}
                                    placeholder="Enter machine hardness_specification"
                                    {...register("hardness_specification")}
                                />

                                {errors.hardness_specification &&
                                    <span className='text-danger'>{errors.hardness_specification?.message}</span>
                                }


                            </div>
                        </div>


                    </form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className='btn btn-yellow px-3'
                        type='submit' onClick={handleSubmit(onSubmit)}>Save</button>
                </ModalFooter>
            </Modal>
            <div className="min-vh-100 " id="root_div_main">
            <div id="mySidebar" className="customsidebar ">
              <a className="closebtn" onClick={() => closeNav()}>
                ×
              </a>

              <div className=" content">
               

                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    { "Search"}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by name"
                    value={filterConfig.searchVal}
                    onChange={(val) => {
                      const inputValue = val.target.value; // Trim leading and trailing whitespaces
                      //  console.log("inputValue",inputValue)
                      if (!inputValue.trim()) {
                        // console.log("inputValue2",inputValue)
                        if (filterConfig.searchVal.length == 1) {
                          // console.log("inputValue3",inputValue)
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }

                        if (inputValue.length == 0) {
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }

                      } else {
                        // console.log("inputValue4",inputValue)
                        setFilterConfig({
                          ...filterConfig,
                          searchVal: inputValue,
                        });
                      }
                    }}
                  />
                </div>

                
                
                <div className="my-3 d-flex justify-content-end">
                  <button
                    onClick={() => clearFilter()}
                    className="btn btn-sm btn-yellow "
                  >
                    <FontAwesomeIcon
                      style={{ color: "#344454" }}
                      icon={faBan}
                      size="sm"
                    />{" "}
                    { "Clear Filter"}
                  </button>
                </div>
              </div>
            </div>
            <div className='hardness_name'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className="page-heading mb-0 fw600">
                       Hardness
                    </h1>
                    <div>
                        <span
                            className="cursor_pointer px-3 me-0 me-md-2"
                            onClick={() => {
                                openNav();
                            }}
                        >
                            <Menu size={18} className='me-1 p-0' />

                            {"Filter Menu"}
                        </span>
                        <button
                            onClick={() => {
                                setVisible(true);
                                setAction({ ...action, state: "create", data: {} });
                                reset();
                            }}
                            className='btn btn-blue'>
                            <Plus size={18} className='me-1 p-0' />Create Hardness
                        </button>
                    </div>
                </div>


                <div className='card mt-3 border-0'>
                    <div className='card-body'>
                        <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
                            <p className="mb-0">
                                Show
                                <select
                                    className="mx-1"
                                    defaultValue={"10"}
                                    onChange={(e) => {
                                        setEntriesPerPage(e.target.value);
                                        fetchHardnessList(page, e.target.value);
                                    }}
                                >
                                    <option value={"10"}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                Entries
                            </p>

                        </div>


                        {dataList.loading ?
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            dataList.data.length == 0 ?
                                <Alert className='text-center' variant={"danger"}>
                                    No Hardness Found
                                </Alert>
                                :
                                <div
                                    className="table-responsive">
                                    <table className="table mt-2 table-striped">
                                        <thead className="table-grey">
                                            <tr className="">
                                                <th scope="col">{"SrNo"}</th>
                                                <th scope="col">
                                                    {"Machine Category"}
                                                </th>
                                                <th scope="col">
                                                    {"Machine Hardness Specification"}
                                                </th>

                                                <th scope="col">
                                                    {"Action"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataList.data.map((val, i) => (
                                                    <tr key={val.uuid}>
                                                        <td>
                                                            {parseInt(i) + 1 + parseInt(srNo)}
                                                        </td>
                                                        <td>{val.hardness_name}</td>
                                                        <td>{val.hardness_specification == null ? "NA" : val.hardness_specification}</td>
                                                        <td>
                                                            <>
                                                                <Tooltip
                                                                    id={"edit-tooltip" + i}
                                                                    place="top"
                                                                />
                                                                <div
                                                                    data-tooltip-id={"edit-tooltip" + i}
                                                                    data-tooltip-content={"Edit Hardness"}
                                                                    className="editIcon m-1 cursor_pointer"
                                                                    onClick={() => {
                                                                        setVisible(true);
                                                                        setAction({ ...action, state: "update", data: val });
                                                                        setValue("hardness_name", val.hardness_name);
                                                                        setValue("hardness_specification", val.hardness_specification);
                                                                    }}
                                                                >
                                                                    <Pencil size={18} color='#fff' />
                                                                </div>
                                                            </>
                                                            <>
                                                                <Tooltip
                                                                    id={"del-tooltip" + i}
                                                                    place="top"
                                                                />
                                                                <div
                                                                    data-tooltip-id={"del-tooltip" + i}
                                                                    data-tooltip-content={"Delete Hardness"}
                                                                    className="deleteIcon m-1 cursor_pointer"
                                                                    onClick={() => {
                                                                        delHardness(val)
                                                                    }}
                                                                >
                                                                    <Trash2 size={18} color='#fff' />
                                                                </div>
                                                            </>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }



                        {dataList.loading ? null : dataList.data?.length > 0 ? (
                            <div className="mt-4 d-flex justify-content-between">
                                <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${dataList.data.length +
                                    entriesPerPage * page -
                                    entriesPerPage
                                    } of ${dataList.totalRecords} entries`}</p>
                                <Pagination>
                                    <Pagination.Prev
                                        disabled={page === 1 ? true : false}
                                        onClick={() => {
                                            fetchHardnessList(
                                                page - 1,
                                                entriesPerPage,
                                                parseInt(srNo) - parseInt(entriesPerPage)
                                            );
                                            setSrNo((prevC) =>
                                                page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                                            );
                                            setPage(page - 1);
                                        }}
                                    >
                                        {"Prev"}
                                    </Pagination.Prev>

                                    <Pagination.Item active>{page}</Pagination.Item>

                                    <Pagination.Next
                                        disabled={
                                            page === dataList.maxPage ||
                                                dataList.maxPage === 0 ||
                                                entriesPerPage > dataList.data.length
                                                ? true
                                                : false
                                        }
                                        onClick={() => {

                                            fetchHardnessList(
                                                page + 1,
                                                entriesPerPage,
                                                parseInt(srNo) + parseInt(entriesPerPage)
                                            );
                                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                                            setPage(page + 1);
                                        }}
                                    >
                                        {"Next"}
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    )
}

export default Hardness