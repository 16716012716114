import React from "react";
import {  Route, Routes, Navigate } from "react-router-dom";
import routes from "../../Common/Routes/Routes";
// import PageNotFound from "./PageNotFound";

function AppContent() {
    const token = localStorage.getItem('mmt_idToken');

    return (
        <Routes>
            {token && token.length > 0 &&
                <>
                    <Route
                        path={"*"}
                        element={<Navigate to={"/404"} />}
                    />
                    
                    <Route path="/" exact={true} element={<Navigate to="/app/dashboard" />} />
                </>


            }

            {token && token.length > 0 ?
                routes.map((route, idx) => {
                    return (
                        route.element && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                // name={route.name}
                                element={<route.element />}
                            />
                        )
                    );
                }) :
                < Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
            }

        </Routes>
    )
}

export default AppContent