export const v_machine_category = "/machine_category"
export const v_machine = "/machine"
export const v_uom = "/uom"
export const v_tooltype = "/tool_type"
export const v_usertype = "/user_type"
export const v_toolmaterial = "/tool_material"
export const v_user = "/user"
export const v_tool_category = "/tool_category"
export const v_tool_subcategory = "/tool_subcategory"
export const v_hardness = "/hardness"
export const v_workpiece_material_classification = "/workpiece_material_classification"
export const v_workpiece_material = "/workpiece_material"
export const v_tool_type_mapping="/tool_type_mapping"
export const v_machine_dim_parameter="/machine_dim_parameters"
export const v_tooling_parameter="/tooling_parameter"
export const v_product="/product"
export const v_common_attributes="/common-attributes"
export const v_leads="/leads"