import axios from 'axios';

// Create an Axios instance
const client1 = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
});


// Request interceptor
client1.interceptors.request.use(
    (config) => {
        // Get the authentication token
        const idToken = localStorage.getItem('mmt_idToken');

        // Add the token to the request headers if it exists
        if (idToken) {
            config.headers['X-ID-TOKEN'] = `${idToken}`;
            config.headers['x-api-key']=`${process.env.REACT_APP_X_API_KEY}`
        }

        return config;


    },
    (error) => {
        console.log("err", error)
        return Promise.reject(error);
    }
);
client1?.interceptors.response.use(
    (res) => {
        // console.log("rres", res);
        return res;
    },

    (error) => {
        console.log("error", error);
        // Handle 401 Unauthorized
        if (error.response.status === 401) {
            console.error('Unauthorized request. Please log in.');
            localStorage.clear();
            window.location.replace(`${process.env.PUBLIC_URL}/login`);

        }

        // Handle 403 Forbidden
        if (error.response.status === 403) {
            localStorage.clear();
            window.location.replace(`${process.env.PUBLIC_URL}/login`);
        }

        // Handle other errors
        if (error.response.status === 500) {
            console.error('An error occurred:', error.message);
        }

        return Promise.reject(error);
    }
);

export default client1