import React from 'react';

function Footer() {
  const presentYear = new Date().getFullYear();

  return (
    <div className='py-2' style={{
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: 'white',
      color: 'black',
      textAlign: 'center',
      zIndex: '99',
      fontSize: "14px"
    }}> <span>Copyright {presentYear} © <a href='https://www.technicious.in/'>Technicious</a> All rights reserved. Version {process.env.REACT_APP_VERSION}</span></div >
  )
}

export default Footer